import React from "react";

import GridTied3 from '../education-images/GridTied3.jpg';

const System = () => {
    return(
        <div>
            <div className="banner3GT">
                <h1>What Your System Will Look Like</h1>
                <img src={GridTied3} alt="pic" />
                <figcaption>Fig 1. Grid Tied Systems</figcaption>
                <h2>Flow of Power in Your Residential Home</h2>
                <h3>
                    Solar will beam onto the panels, creating DC power, 
                    which then gets converted into AC power for 
                    residential usage through the Inverter.
                </h3>
            </div>
        </div>
    )
}

export default System;