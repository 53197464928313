import React, {useState} from 'react';
import {motion} from 'framer-motion';
import {useNavigate} from 'react-router-dom';

/* import CustomCheckbox from './CustomCheckbox.js'; */

import Question from './Question.js';
import rvQuestionsData from './rvQuestionsData.js';
import residentialQuestionsData from './residentialQuestionsData.js';
import commercialQuestionData from './commercialQuestionData.js';
import offgridQuestionsData from './offgridQuestionsData.js';

import EmailForm from './EmailForm.js';

import '../styles/quote.css';


const QuoteForm = ({type}) => {
    /*const [checkedValues, setCheckedValues] = useState({}); */
    const [answers, setAnswers] = useState({});
    const [address, setAddress] = useState({});
    const [charCount, setCharCount] = useState(0);
    const [text, setText] = useState("");
    const [otherAnswers, setOtherAnswers] = useState({});

    const navigate = useNavigate();

    let formType;
    let quoteHeader;
    let form;

    switch (type) {
        case "residential":
          formType = residentialQuestionsData;
          quoteHeader = "Residential House";
          form = "Residential";
          break;
        case "commercial":
          formType = commercialQuestionData;
          quoteHeader = "Commercial or Business Location";
          form = "Commercial";
          break;
        case "offGrid":
          formType = offgridQuestionsData;
          quoteHeader = "Off-Grid Homes";
          form = "Off-Grid";
          break;
        case "rv":
          formType = rvQuestionsData;
          quoteHeader = "RV or Camper";
          form = "RV";
          break;
        default:
          formType = null; // or some default case
      }
      

    const handleBatteryRecommendation = (e) => {
        const userConfirmation = window.confirm("Are you sure you want to leave this page?");
        if (userConfirmation) {
            navigate(`../../Education/${e.currentTarget.getAttribute(`data-value`)}`);
            window.scrollTo(0, 0);            
        }
    }

    const handleCommentChange = (e) => {
        const inputText = e.target.value;
        setCharCount(inputText.length);
        setText(inputText);
        setAnswers(prev => ({
            ...prev,
            message:inputText,
        }))
    }

    const handleAnswer = (e) => {
        try{
            const info = e.target.value || e.target.getAttribute(`data-value`);
            const questionValue = e.target.name;
            setAnswers(prev => ({
                ...prev,
                [questionValue]:info,
            }));
        } catch (error) {
            console.error("Failed to parse the value into an object:", error);
        }
    }

    const handleOptionClick = (e) => {
        const info = e.currentTarget.getAttribute('data-value');
        const currentValue = answers[info];
        setAnswers(prev => ({
            ...prev, 
            [info]:!currentValue
        }))
    }

    const containerVariants = {
        hidden: { opacity: 1 },
        visible: {
        opacity: 1,
        transition: {
            // This will stagger the children's individual animations by 0.3 seconds.
            staggerChildren: 0.3
        }
        }
    };

    const childVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 }
    };


    const handleFormSubmit = (e) => {
        e.preventDefault();
        console.log({...answers, ...address, ...otherAnswers});
        console.log(JSON.stringify({...answers, ...address}));
    }

    const backToTop = () => {
        window.scrollTo({
            top:0,
            behavior:"smooth"
        });
    }

  return (
    <motion.div
    variants={containerVariants}
    initial="hidden"
    animate="visible"
    className="quoteContent"
    >
        <motion.div
        variant={childVariants}
        transition={{ duration: 0.3 }}
        className="backButton"
        onAnimationComplete={() => {
          document.getElementById("quoteTitle").scrollIntoView({
            behavior:'smooth'
          })
        }}
        >
          <button type="button" id="quoteTitle" onClick={() => navigate(-1)}>Go Back to Selection</button>
        </motion.div>
        <form 
            onSubmit={handleFormSubmit} 
            className="bannerContainer"
            style={{fontSize:"x-large"}}
        >
            <div className="quoteHeader">
                <h1 id="quoteTitle">Quote for {quoteHeader}</h1>
                <h3>Click "Request Quote" on the side once you fill as much info as you can.</h3>
                <h3>Once the form is submitted, we will reach out within 1-2 business days.</h3>
                <h4>(<span style={{"color":"red"}}>*</span>) Are mandatory</h4>
            </div>
            {formType.map((question, index) => {
                return(
                    <motion.div
                      variants={childVariants}
                      transition={{ duration: 0.3 }}
                      className={`container${(index % 2) + 1}`}
                    >
                        <div className="questionContainer">
                          <h2>Question #{index + 1}</h2>
                          <Question 
                            key={question.id}
                            question={question}
                            onChange={handleAnswer}
                            value={answers[question.id] || ''}
                            onOptionClick={handleOptionClick}
                            currentAnswers={answers}
                            onRecommendationClick={handleBatteryRecommendation}
                            address={address}
                            setAddress={setAddress}
                            charCount={charCount}
                            text={text}
                            handleCommentChange={handleCommentChange}
                            otherAnswers={otherAnswers}
                            setOtherAnswers={setOtherAnswers}
                          />
                        </div>
                    </motion.div>
                )
            })}              
            <EmailForm onSubmit={handleFormSubmit} answers={answers} address={address} otherAnswers={otherAnswers} form={form}/>
        </form>
        <button className="backToTop" onClick={backToTop}>Back To Top</button>
    </motion.div>
  )
}


export default QuoteForm; 