import React from "react";

import NewsPicture from './images-home/News.jpg';
import icon from './images-home/facebook.svg';
import { useSelector } from "react-redux/es/hooks/useSelector";

const News = () => {
  const db = useSelector((state) => state.firebaseDB.products)
  let news;
  if(db){
    news = db.SimpleIndyNews;
  }
    return(
        <div className="News">
        <h1>
            Simple Indy News
        </h1>
            <div className="doubleContainer">
                <div className="leftContainer">
                    <img src={NewsPicture} alt="pic" />
                </div>
                <div className="rightContainer">
                    <h2>{news ? `${news.News}` : ""}</h2>
                    <a
                        href="https://www.facebook.com/simpleindyllc" 
                        target="_blank" 
                        rel="noopener noreferrer"
                    >
                        <img className="smallIcon" src={icon} alt="icon" />
                    </a>
                </div>
            </div>
        </div>
    )
}

export default News;