import React from 'react';
import '../styles/quote.css'
import { NavLink } from 'react-router-dom';
import { motion } from 'framer-motion';

import QR1 from './quote-images/QR-1.jpg'

import QC1 from './quote-images/QC-1.jpg';

import QOF1 from './quote-images/QOF-1.jpg';

import QRV1 from './quote-images/QRV-1.jpg';

const ChooseYourQuote = (props) => {

    const containerVariants = {
        hidden: { opacity: 1 },
        visible: {
        opacity: 1,
        transition: {
            // This will stagger the children's individual animations by 0.3 seconds.
            staggerChildren: 0.3
        }
        }
    };

    const variant = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 }
    }

    const navigation = [
        {
            nav:"./residential",
            img: QR1,
            name:"Residential"
        },
        {
            nav:"./commercial",
            img: QC1,
            name:"Commercial"
        },
        {
            nav:"./offgrid",
            img: QOF1,
            name:"Off-Grid"
        },
        {
            nav:"./rv",
            img: QRV1,
            name:"RV"
        },
    ]


    return (
        <div className="quote">
            <h1 className="quoteh1">Get Your Quote Today</h1>
            <h2 className="quoteh2">Choose a Category</h2>
            <motion.div 
                className="options"
                initial="hidden"
                animate="visible"
                variants={containerVariants}
            >
                {navigation.map((navigation, index) => {
                    return(
                        <NavLink to={navigation.nav}>
                            <motion.div
                                className="option"
                                variants={variant}
                                transition={{duration:0.2}}
                            >
                                <img src={navigation.img} alt="pic"/>
                                <h2>{navigation.name}</h2>
                            </motion.div>
                        </NavLink>
                    )
                })}
            </motion.div>
        </div>
    )
}

export default ChooseYourQuote;