import React, { useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import "../styles/home.css";
import CarrouselContent from "./Carrousel-Content";
//import { Link } from 'react-router-dom';

const Carrousel = (props) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);
  const [direction, setDirection] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  const carouselContent = [
    <CarrouselContent value={props.value[0]}/>,
    <CarrouselContent value={props.value[1]}/>,
    <CarrouselContent value={props.value[2]}/>,
    // ... add more content as needed
  ];

  const handleNext = () => {
    if(isAnimating) return;
    setIsAnimating(true);
    setDirection(1);
    setCurrentIndex((prevIndex) => (prevIndex + 1) % carouselContent.length);
  };

  const handlePrev = () => {
    if(isAnimating) return;
    setIsAnimating(true);
    setDirection(-1);
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + carouselContent.length) % carouselContent.length
    );
  };

  const handleTouchStart = (e) => {
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (touchStart - touchEnd > 75) {
      handleNext();
    }

    if (touchStart - touchEnd < -75) {
      handlePrev();
    }
  };

  // Set up automatic transition
  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 6000); // Change transition interval as needed

    // Clear interval on component unmount
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, [currentIndex]);

  const variants = {
    enter: { opacity: 0 },  // Start with 0 opacity (invisible)
    center: { opacity: 1 },  // Animate to 1 opacity (fully visible)
    exit: { opacity: 0 }  // Animate back to 0 opacity
  };
  
  return (
      <div className="banner carousel">
        <button className="leftButton" onClick={handlePrev}>&lt;</button>
        <div
          className="carousel-container"
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          <AnimatePresence initial={false} custom={direction} onExitComplete={() => setIsAnimating(false)}>
          <motion.div
            key={`carousel-item-${currentIndex}`}  // This key is important for letting React know the item has changed
            initial="enter"  // The initial state of the component, before it "enters"
            animate="center"  // The state of the component when it's "center" (i.e., present)
            exit="exit"  // The state of the component when it's exiting
            variants={variants}  // The actual animations
            transition={{ duration: 0.3 }}  // How long the fade effect should last
            style={{ width:"100%", height:"100%"}}
            className="carousel-content"
          >
              {carouselContent[currentIndex]}
            </motion.div>
          </AnimatePresence>
        </div>
        <button className="rightButton" onClick={handleNext}>&gt;</button>
      </div>
    );
}

export default Carrousel;
