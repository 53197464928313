import React from "react";

import OffGrid3 from '../education-images/OffGrid3.jpg';
import OffGrid1 from '../education-images/OffGrid1.webp';

const System = () => {
    return(
        <div className="banner3OG">
            <h1>What Your System Will Look Like</h1>
            <div className="figures">
                <div className="figure">
                    <img src={OffGrid3} alt="pic"/>
                    <figcaption><i>Figure 1. Off Grid System</i></figcaption>                    
                </div>
                <div className="figure needWhiteBackground">
                    <img src={OffGrid1} alt="pic"/>
                    <figcaption><i>Figure 2. Victron Energy Online VRM Monitoring</i></figcaption>                    
                </div>
            </div>
            <h2>
            Stay Connected, Anytime, Anywhere.
            </h2>
            <p>
                Harness the latest advancements from Victron Energy to 
                keep an eye on your off-grid system, no matter your location. Our intuitive online platform ensures you're always in the loop, right from your phone or any other internet-enabled device.
            </p>

            <h2>
                Deepen Your Insight Over Time
            </h2>
            <p>
                Access extensive historical data to gain a comprehensive 
                understanding of your power consumption and generation patterns. 
                Your system's performance data evolves month after month, offering 
                you insights to optimize your energy usage.
            </p>

            <h2>
                A Hands-Free Experience: Fully Automated Systems
            </h2>
            <p>
                Our commitment is to ensure simplicity for our customers. 
                We've designed our off-grid systems to be fully automated, 
                so you can enjoy uninterrupted power without lifting a 
                finger. Experience the true essence of convenience with our 
                smart, user-centric designs.
            </p>
      </div>
    )
}

export default System;