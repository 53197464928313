import React from "react";

import solar1 from '../education-images/Solar1.jpg';

const SolarInfo = () => {
    return(
        <div>
            <div className="banner1SP">
                <h1 id="solarPanels">Solar Panels</h1>
                <h2>How Does A Solar Panel Work?</h2>
                <div className="solarInfo">
                    <img alt="pic" src={solar1}/>
                    <div className="explanation">
                        <p>
                        <strong>Solar panels</strong> work on the principle of the photovoltaic effect. 
                        When certain materials are exposed to light, they absorb 
                        photons and release electrons, creating an electric current. 
                        Here's a step-by-step breakdown of the process:
                        </p>
                        <ol>
                        <li>
                        Solar panels are composed of many smaller units called 
                        photovoltaic (PV) cells, which are typically made from silicon 
                        and are treated to form an electric field, positive on one side 
                        and negative on the other.
                        </li>
                        <li>
                        When sunlight strikes a PV cell, it may be reflected, pass 
                        through, or be absorbed. Only the absorbed photons generate 
                        electricity. When this happens, the energy of the absorbed light 
                        is transferred to electrons in the atoms of the PV cell.
                        </li>
                        <li>
                        With their newfound energy, these electrons escape from their 
                        normal position in the atoms of the silicon to become part of an 
                        electric current. This is the "photoelectric effect" first 
                        explained by Albert Einstein.
                        </li>
                        <li>
                            The electric current, together with the cell's voltage 
                            (a result of its built-in electric field or fields), defines
                            the power (or wattage) that the solar cell can produce.
                        </li>
                        <li>
                            The electricity generated by a single solar cell is small, 
                            but by connecting many cells together in a panel and many 
                            panels together in an array, a significant amount of energy 
                            can be collected.
                        </li>
                        <li>
                            The Direct Current (DC) electricity then flows to an inverter, 
                            which converts it into Alternating Current (AC) electricity 
                            for household or business use.
                        </li>
                        </ol>
                    </div>                
                </div>          

            </div>
        </div>
    )
}

export default SolarInfo;