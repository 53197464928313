import React from "react";

import RV2a from '../education-images/RV2a.png';
import RV2b from '../education-images/RV2b.png'

const Q1HowManyBattery = () => {
    return(
      <div className="banner3RV">
        <h2>Evaluating Lithium Battery Requirements</h2>
        <h3>Determine Your Overnight Power Sufficiency</h3>
        <ul>
          <li><strong>Sufficient Overnight Power?</strong><br/>If your existing batteries last through the night, the equivalent Lithium battery capacity you require is half your current ampere-hours (Ah).</li>
          <li><strong>Example</strong>:<br/>With 400Ah of lead-acid batteries, you would need 200Ah in Lithium batteries.</li>
          <div className="comparison">
            <section className="compareSection">
              <img src={RV2a} alt="Comparative illustration of battery capacities"/>
              <h2>4 Lead Acid Battery</h2>
              <h3>392Ah (Can Only use 196Ah)</h3>
              <h3>Weight: 200lb</h3>
              <h3>Average Life: 2-5 years</h3>
              <h3>Maintenance: Monthly</h3>
            </section>
            <h2>VS</h2>
            <section className="compareSection">
              <img src={RV2b} alt="Comparative illustration of battery capacities"/>
              <h2>2 Lithium Battery</h2>
              <h3>200Ah (Can Use All Of It)</h3>
              <h3>Weight: 58lb</h3>
              <h3>Average Life: 15-25 years</h3>
              <h3>Warranty: 10 to 12 years</h3>
              <h3>No Maintenance</h3>
            </section>
          </div>
          <li><strong>Insufficient Power?</strong><br/>If your current setup does not last overnight, proceed to the next question for further assessment.</li>
        </ul>
      </div>
    )
}

export default Q1HowManyBattery;