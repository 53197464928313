import React from "react";
import lightBulb from '../children-home/images-home/AboutUs2.jpg'

const Confirmation = () => {

  return(
    <div className="confirmationPage quoteConfirmation">
      <div className="left">
        <h1>Thank You!</h1>
        <h2>We got your Request!</h2>
        <h3>We will reach out to you within 3-5 business days.</h3>
        <h3>
          For any questions or concerns, please 
          email <a href="mailto:sales@simpleindy.com">sales@simpleindy.com</a>
        </h3>
      </div>
      <div className="right">
        <img src={lightBulb} alt="green light bulb"/>
        <h2>Making our Home Greener</h2>
      </div>
    </div>
  )
}

export default Confirmation;