import React, {useState, useEffect} from 'react';
import '../styles/quote.css';
import { useSelector, useDispatch } from 'react-redux';
import { setAnswers } from '../../Redux_Toolkit/conditionalQuestionSlice';
import { motion } from 'framer-motion';

//import CustomCheckbox from './CustomCheckbox';
import CustomRadio from './CustomRadio';

function Question({ 
  question, 
  onChange, 
  value, 
  onOptionClick, 
  currentAnswers, 
  onRecommendationClick, 
  address, 
  setAddress,
  charCount,
  text,
  handleCommentChange,
  otherAnswers,
  setOtherAnswers,
}) {

  const FollowUpFunction = () => {

    if (!question.followUp || currentAnswers[question.id] !== "Yes") {
      return null;
    }

    return (
      <div>
        {question.followUp && currentAnswers[question.id] === "Yes" ?
          <div>
            <h3>{question.followUp.text}</h3>
            <label> {question.followUp.text2}
              <select
                onChange={onChange}
                name={question.followUp.id}
                className="followUpInput"
                value={currentAnswers[question.followUp.id]}
              >
                {question.followUp.select}
              </select>
            </label>
            {question.followUp.select2 ? (
              <label> {question.followUp.text3}
                <select
                  onChange={onChange}
                  name={question.followUp.id2}
                  className="followUpInput"
                  value={currentAnswers[question.followUp.id2]}
                >
                  {question.followUp.select2}
                </select>
              </label>              
            ) : null}
          </div>
        : null}
      </div>
    )
  }

  const FollowUpFunctionForCombo = ({followUpRequiredValue}) => {
    return (
      <div>
        {question.followUp && currentAnswers[followUpRequiredValue] ?
          (
          <div className="select">
            <h3>{question.followUp.text}</h3>
            <select
              onChange={onChange}
              name={question.followUp.id}
              value={currentAnswers[question.followUp.id]}
            >
              {question.followUp.select}
            </select>              
            
          </div>
        ) : null}        
      </div>
    )
  }

  const Recommendation = () => {
/*     return(
      <div className="recommendation">
        {question.recommendation ? 
          (<button 
            type="button"
            onClick={onRecommendationClick}
            className="linkBox"
            data-value="gridTiedBatteries"
          >
            <h3>Click Here to Learn Why</h3>
          </button>) : "" }
      </div>      
    ) */
  }

  const addressParameters = [
    "*First Name",
    "*Last Name",
    "Address",
    "City",
    "State",
    "Zip",
    "*Email",
    "*Phone"
  ]

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
    opacity: 1,
    transition: {
        // This will stagger the children's individual animations by 0.3 seconds.
        staggerChildren: 0.5
    }
    }
  };

  const childVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 }
    
  };

  const RvBatteryRecommendation = () => {
    const [curBatteryQuestion, setCurBatteryQuestion] = useState(false);
    const [newBatteryQuestion, setNewBatteryQuestion] = useState(false);
    const [solarQuestion, setSolarQuestion] = useState(false);
    const [breakerQuestion, setBreakerQuestion] = useState(false);
    const solar = currentAnswers["solar"];
    const lithium = currentAnswers["lithium_batteries"];
    const system = currentAnswers["system_upgrade"];
    const breakers = ["1 Leg", "Both Legs"]

    const dispatch = useDispatch();
    const answers = useSelector((state) => state.conditionalQuestions.answers);

    const handleAnswers = (e) => {
      const name = e.target.name;
      const value = e.target.value;
/*       setLogicAnswers((prev) => ({
        ...prev, 
        [name]:value,
      })); */
      dispatch(setAnswers({ name, value }));
    }

    useEffect(() => {
      setCurBatteryQuestion(lithium || solar)
      setNewBatteryQuestion(lithium);
      setSolarQuestion(solar);
      setBreakerQuestion(system)

    }, [solar, lithium, system]);

    return(
      <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        {curBatteryQuestion ? (
          <motion.div
            variant={childVariants}
          >
            <h3>How many Amp Hours do we currently have in House Batteries? (Lead-Acid)</h3>
            <input 
              type="number" 
              name="currentBatteries" 
              onChange={handleAnswers} 
              value={answers["currentBatteries"]}
              className="textInputForAh"
              />
              <span>Ah</span>
          </motion.div>
        ):null}

        {newBatteryQuestion ? (
          <motion.div
            variant={childVariants}
          >
            <h3>How many Amp Hours worth of Lithium are we looking for?</h3>
            <input 
              type="number"
              name="lithiumBatteries"
              onChange={handleAnswers}
              value={answers["lithiumBatteries"]}
              className="textInputForAh"
            />
            <span>Ah</span>
            <h4 className="recommendationForNewBatteries">Recommendation:</h4>
            <section  className="batteryRecommendation">
              <h4>Direct Replacement: {answers["currentBatteries"]/2}</h4>
              <h4>Minimum Requirement for a 3000W Inverter: 300Ah</h4>
              <h4>Double the Power: {answers["currentBatteries"]}</h4>
            </section>
            <button
              type="button"
              onClick={onRecommendationClick}
              className="linkBox"
              data-value="rv"
            >
              Click Here to Learn More
            </button>
          </motion.div>
        ):null}

        {solarQuestion ? (
          <motion.div
            variant={childVariants}
          >
            <h3>How many panels are we looking to install? (We install 200W Panels)</h3>
            <input 
              type="number"
              name="solarPanels"
              onChange={handleAnswers}
              value={answers["solarPanels"]}
              className="textInputForAh"
            />
            <h4 className="recommendationForNewBatteries">Recommendation:*</h4>
            <section  className="batteryRecommendation">
              <h4>Low-End: 
                {lithium ? 
                `${answers["lithiumBatteries"]/2/37.5} Panels` 
                :
                `${answers["currentBatteries"]/2/37.5} Panels`}
              </h4>

              <h4>Mid Tier:
                {lithium ? 
                `${answers["lithiumBatteries"]*0.75/37.5} Panels ` 
                :
                `${answers["currentBatteries"]*0.75/37.5} Panels`}
              </h4>
              <h4>High-End:
                {lithium ? 
                  `${answers["lithiumBatteries"]/37.5} Panels` 
                  :
                  `${answers["currentBatteries"]/37.5} Panels`}  
              </h4>
            </section>
            <section className="disclaimer">
              <p>*: This is an estimated calculation assuming a bright sunny day.</p>
              <p>Low-End: restores approximately 50% of the battery capacity throughout a day.</p>
              <p>Middle: restores approximately 75% of the battery capacity throughout a day.</p>
              <p>High-End: restores approximately 100% of the battery capacity throughout a day.</p>              
            </section>

          </motion.div>
        ):null}

        {breakerQuestion ? (
          <motion.div
            variant={childVariants}
          >
            <h3>How much of the breaker box do we want to power when not plugged in or running the Generator?</h3>
            <div className="breakerRadioContainer">
              {breakers.map((choice, index) => (
                <label>
                  {choice}
                  <CustomRadio
                    name="breakerQuestion"
                    isChecked={answers["breakerQuestion"] === choice}
                    onRadioChange={handleAnswers}
                    dataValue={choice}
                  />
                </label>
              ))}             
            </div>
          </motion.div>
        ):null}
      </motion.div>
    )
  }

  switch (question.type) {
    case 'quoteHeader':
      return(
        <div className="quoteHeader">
          <h1 id="quoteTitle">{question.text1}</h1>
          <h3>{question.text2}</h3>                
        </div>
      )

    case 'select':
      return (
        <div className="select">
          <h3>{question.text}</h3>
          <label htmlFor={question.id}>{question.label}</label>
          <select
              id={question.id}
              onBlur={onChange}
              name={question.id}
          >
              {question.select}
          </select>
        </div>
      );
      
    case 'multipleChoice':
      return (
        <div  className="multipleChoice">
          <h3>{question.text}</h3>
          <div className="choices">
            {question.select.map((choice, index) => (
              <label>
                {choice}<pre/>
                <CustomRadio
                  name={question.id}
                  isChecked={currentAnswers[question.id] === choice}
                  onRadioChange={onChange}
                  dataValue={choice}
                />
              </label>
            ))}            
          </div>
          <Recommendation />
          <FollowUpFunction/>
        </div>
      );

    case 'multipleChoiceWithImages':
      return (
        <div>
          <h3>{question.text}</h3>
          <h4 style={{textAlign:"center"}}>{question.text2}</h4>
          <div className={`${question.className} multipleChoiceWithImages`} >
            {question.select.map((choice, index) => (
              <div 
                className={`optionContainer ${choice.className} ${currentAnswers[choice.value] ? 'clicked' : ''}`} 
                onClick={onOptionClick}
                data-value={choice.value}
              >
                <img src={question.images[index]} alt="pic" />
                <h3>{choice.displayValue}</h3>
              </div> 
            ))} 
          </div>
          <FollowUpFunctionForCombo followUpRequiredValue={question.followUpRequiredValue}/>
          {question.special === "batteryRecommendation" ? <RvBatteryRecommendation/>:""}
        </div>
      )

    case 'textInput':
      return(
        <div className="textInputContainer">
          <h3>{question.text}</h3>
          <input 
            type="text"
            name={question.id}
            onChange={onChange}
            value={currentAnswers[question.id]}
            className="textInput"
          />
        </div>
      )

    case 'address':
      const handleAddressInputs = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setAddress(prev => ({
          ...prev,
          [name] :value
        }))
      }

      return (
        <div>
          <h3>{question.text}</h3>
          <div className="addressContainer">
            {addressParameters.map((para, id) => (
              para.includes("*") ? (
                <div className="addressParameter">
                  <label htmlFor={id}><span style={{color:"red"}}>*</span>{para.slice(1)}</label>
                  <input id={id} onBlur={handleAddressInputs} name={para} required/>
                </div>
              )
              :
              <div className="addressParameter">
                <label htmlFor={id}>{para}</label>
                <input id={id} onBlur={handleAddressInputs} name={para} />
              </div>
            ))}            
          </div>
        </div>
      )

    case 'input':
      return(
        <div className="inputContainer">
          <h4>{question.text}</h4>
          <textarea 
            id="customer-input" 
            name="customer-input" 
            rows="10" 
            cols="50" 
            wrap="soft" 
            placeholder="Please share any details that will help us provide you with the best possible service." 
            maxLength="400"
            value={text}
            onChange={handleCommentChange}
          />
          <p className="charCount">{charCount}/400</p>
        </div>
      )

    default:
      return null;
  }
}
  
export default Question;