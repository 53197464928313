import './App.css';
//import icon from './icon.png'
import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter as Router, NavLink } from 'react-router-dom';
import { useDispatch} from 'react-redux';
import { fetchData } from './Redux_Toolkit/firebaseSlice.js';
import { fetchUpsToken } from './Redux_Toolkit/ups_token.js';
import Logo from './components/Logo.js';
import Media from './components/children-home/Media';
import Body from './Body.js';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: "simpleindy-c54d3.firebaseapp.com",
  projectId: "simpleindy-c54d3",
  storageBucket: "simpleindy-c54d3.appspot.com",
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.FIREBASE_APP_ID,
  measurementId: "G-TDTDRTML23"
};

// Initialize Firebase
initializeApp(firebaseConfig);

function App() {
  const dispatch = useDispatch();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const resultRef = useRef(null)
  //const { token } = useSelector((state) => state.upsToken);

  const scrollToTop = () => {
    window.scrollTo({
      top:0,
      behavior:'smooth',
    });
  }

  useEffect(() => {
    dispatch(fetchUpsToken());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchData());
  }, [dispatch])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (resultRef.current && !resultRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }      
    };

    document.addEventListener('mousedown', handleClickOutside);
    return() => {
      document.removeEventListener('mousedown', handleClickOutside);
    }
  }, []);

  return (
    <Router>
      <header>
        <div className="headerContainer">
          <Logo/>
          <nav ref={resultRef}>
            <ul className={`navbar-links ${isMenuOpen ? 'open' : ''}`}>
              <button className="menu-button" onClick={() => setIsMenuOpen(!isMenuOpen)} >✕</button>
              <NavLink to="/" activeClassName={"active"} onClick={() => setIsMenuOpen(false)}>Home</NavLink>
              <NavLink to="/About_Us" activeClassName={"active"} onClick={() => setIsMenuOpen(false)}>About</NavLink>
              {/* <NavLink to="/Education" activeClassName={"active"} onClick={() => setIsMenuOpen(false)}>Education</NavLink> */}
              <NavLink to="/Overall_Products" activeClassName={"active"} onClick={() => setIsMenuOpen(false)}>Products</NavLink>
              <NavLink to="/Contact" activeClassName={"active"} onClick={() => setIsMenuOpen(false)}>Contact</NavLink>
              <NavLink to="/Quote" activeClassName={"active"} onClick={() => setIsMenuOpen(false)}>Quote</NavLink>
            </ul>
            <button className="menu-button" onClick={() => setIsMenuOpen(!isMenuOpen)} >☰</button>
          </nav>
        </div>
      </header>
      <Body />
      <footer>
        <Media/>
        <NavLink to="/PrivacyPolicy" activeClassName={"active"} onClick={scrollToTop}>Privacy Policy</NavLink>
        <p>© 2022 Simple Indy LLC</p>
      </footer>
    </Router>
  );
}

export default App;
