import React from 'react';
import { motion } from 'framer-motion';

import SolarInfo from './children-SolarPanels/SolarInfo.js';
import SolarInfluence from './children-SolarPanels/SolarInfluence.js';
import TypesOfSolar from './children-SolarPanels/TypesOfSolar.js';
import Next from './children-SolarPanels/Next.js';

const SolarPanels = () => {

  const content = [SolarInfo, TypesOfSolar, SolarInfluence, Next];

  const containerVariants = {
      hidden: { opacity: 1 },
      visible: {
        opacity: 1,
        transition: {
          // This will stagger the children's individual animations by 0.3 seconds.
          staggerChildren: 0.3
        }
      }
    };

    const childVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 }
    };

    return(
    <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        className="bannerContainer"
      >

        {content.map((Component, index) => (
          <motion.div
            variants={childVariants}
            transition={{duration:0.3}}
            onAnimationComplete={index===0 ? () => {
              document.getElementById('solarPanels').scrollIntoView({
                behavior:'smooth'
              })
            } : undefined}
            className={`container${index % 2 + 1}`}
          >
            <Component />
          </motion.div>
        ))}
    </motion.div>
    )
}

export default SolarPanels;
