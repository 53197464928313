import React from "react";

const OffGridInfo = () => {
  return(
    <div className="banner1OG">
      <h1 id="offGrid">Off Grid Systems</h1>
      <h2>Why Go Off-Grid?</h2>
      <ul>
        <li>
          <h3>Energy Autonomy:</h3>
          <p>
            Break free from utility companies and 
            generate your own clean, renewable energy.
          </p>
        </li>
        <li>
          <h3>Environmental Stewardship</h3>
          <p>
            Reduce your carbon footprint and 
            contribute to a greener planet.
          </p>
        </li>
        <li>
          <h3>Financial Savings</h3>
          <p>
            Save on energy bills and potentially generate surplus energy.
          </p>
        </li>
        <li>
          <h3>Remote Possibilities</h3>
          <p>
            Extend reliable power to remote locations, 
            opening new living and working opportunities.
          </p>
        </li>
        <li>
          <h3>Resilient Living</h3>
          <p>
            Ensure a stable energy supply, even during outages and disruptions.
          </p>
        </li>
      </ul>
    </div>
  )
}

export default OffGridInfo;