import React, {useState, useEffect} from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion';
import { useSelector } from 'react-redux/es/hooks/useSelector.js';

const JobArticle2 = () => {
  const db = useSelector((state) => state.firebaseDB.products);
  const [numOfStars, setNumOfStars] = useState("");
  let job;
  let rating = 0;
  if(db.RecentJobs){
    job = db.RecentJobs;
    rating = db.RecentJobs.Jobs[1].rating[0];
  }

  useEffect(() => {
    if(rating > 0){
      switch(rating){
        case "0":
          setNumOfStars("☆☆☆☆☆")
          break;
        case "1":
          setNumOfStars("⭐☆☆☆☆")
          break;
        case "2":
          setNumOfStars("⭐⭐☆☆☆")
          break;
        case "3":
          setNumOfStars("⭐⭐⭐☆☆")
          break;
        case "4":
          setNumOfStars("⭐⭐⭐⭐☆")
          break;
        case '5':
          setNumOfStars("⭐⭐⭐⭐⭐")
          break;
        default:
          setNumOfStars("N/A")
      }      
    }
  }, [rating])

    const navigate = useNavigate();

    const handleBackButton = () => {
        navigate('/About_Us');
    }

    const handlePreviousButton = () => {
        navigate('/About_Us/JobArticle-1')
    }

    const handleNextButton = () => {
        navigate('/About_Us/JobArticle-3')
    }

    const variantContainer = {
        hidden: {opacity: 1},
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.3
            }
        }
    }

    const childrenVariant = {
        hidden: {opacity: 0},
        visible: {opacity: 1}
    }

    return(
      <>
      {job ? 
      <div className="jobArticle">
        <button onClick={handleBackButton}>Back To About Us</button>
        <div className="navigateArticle">
          <button onClick={handlePreviousButton}>Previous Article</button>
          <nav>
            <Link to="../About_Us/JobArticle-1">
                <FontAwesomeIcon 
                    icon={faCircle} 
                    className="dot"
                />
            </Link>
            <Link to="../About_Us/JobArticle-2">
                <FontAwesomeIcon 
                    icon={faCircle} 
                    className="activeDot"
                />
            </Link>
            <Link to="../About_Us/JobArticle-3">
                <FontAwesomeIcon 
                    icon={faCircle} 
                    className="dot"/>
            </Link>                 
          </nav>
          <button onClick={handleNextButton}>Next Article</button>
        </div>
        <motion.div
          variants={variantContainer}
          initial="hidden"
          animate="visible"
        >
          <motion.div
              className="articleTitle"
              variants={childrenVariant}
              transition={{duration: 0.3}}
          >
            {job ? <h1>{`Article - ${job.Jobs[1].name}`}</h1> : "" }              
          </motion.div>
          <div className="info">
            <motion.div 
              className="jobContent article"
              variants={childrenVariant}
              transition={{duration: 0.3}}
            >
              <img src={job.Jobs[1].image1} alt={`${job.Jobs[1].name} pic 1`} style={{ width: '400px', height: '300px'}}/>
              <div className="jobDescription">
                <h2><u>System Info</u></h2>
                <h2>{job ? `${job.Jobs[1].numOfPanels} ${job.Jobs[1].panelBrand} Panels - ${job.Jobs[1].systemSize} System` : ""}</h2> 
                <h2>{job ? job.Jobs[1].offset : ""} Offset</h2>                   
              </div>
            </motion.div>
            <motion.div 
              className="jobContent article"
              variants={childrenVariant}
              transition={{duration: 0.3}}
            >
              <img src={job.Jobs[1].image2} alt={`${job.Jobs[1].name} pic 2`} style={{ width: '400px', height: '300px'}}/>
              <div className="jobDescription">
                <h2><u>Rating</u></h2>
                <h2>{job ? job.Jobs[1].name : ""} Rated Us: {job.Jobs ? job.Jobs[1].rating : ""}</h2>
                <p className="stars">{numOfStars}</p>                      
              </div>
            </motion.div>
                  
            <motion.div 
              className="jobContent article"
              variants={childrenVariant}
              transition={{duration: 0.3}}
            >
              <img src={job.Jobs[1].image3} alt={`${job.Jobs[1].name} pic 3`} style={{ width: '400px', height: '300px'}}/>
              <div className="jobDescription">
                <h2><u>Customer Feedback</u></h2>
                <h2>{job ? job.Jobs[1].customerFeedback : ""}</h2> 
              </div>
            </motion.div>
          </div>
      </motion.div>
      <div className="navigateArticle">
        <button onClick={handlePreviousButton}>Previous Article</button>
        <nav>
          <Link to="../About_Us/JobArticle-1">
            <FontAwesomeIcon 
              icon={faCircle} 
              className="dot"
            />
          </Link>
          <Link to="../About_Us/JobArticle-2">
            <FontAwesomeIcon 
                icon={faCircle} 
                className="activeDot"
            />
          </Link>
          <Link to="../About_Us/JobArticle-3">
            <FontAwesomeIcon 
                icon={faCircle} 
                className="dot"
            />
          </Link>
          </nav>
          <button onClick={handleNextButton}>Next Article</button>
        </div>
      </div> 
      :
      ""}    
    </>
    )
}

export default JobArticle2