// slice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  cart: [
  ],
};

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setCart: (state, action) => {
      const payload = action.payload;
      const newItem = {
        product:payload.product,
        quantity:payload.quantity,
        productType:payload.productType,
      }
      const index = state.cart.findIndex(item => item.product === newItem.product);
      if (index !== -1 && payload.quantityChange === "add") {
        state.cart[index].quantity += newItem.quantity;
      } else if (index !== -1 && payload.quantityChange === "subtract") {
        state.cart[index].quantity -= newItem.quantity;
        if(state.cart[index].quantity < 1) {
          state.cart.splice(index, 1);
        }
      } else if(index !== -1 && payload.quantityChange === "set") {
        state.cart[index].quantity = newItem.quantity;
        if(state.cart[index].quantity < 1) {
          state.cart.splice(index, 1);
        }
      } else {
        // Add new item
        state.cart.push(newItem);
      }
    },
    clearCart: (state) => {
      state.cart = [];
    }
  },
});

// Export the action creator
export const { setCart, clearCart } = cartSlice.actions;

// Export the reducer
export default cartSlice.reducer;
