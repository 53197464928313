import React from "react";

import RV7 from '../education-images/RV7.png'
import RV8 from '../education-images/RV8.png'
import RV9 from '../education-images/RV9.png'
import RV10 from '../education-images/RV10.png'


const Q1And2Conclusion = () => {
    return(
      <div className="banner5RV">
        <h2>Determining Your Daily Power Usage</h2>
        <div className="banner5RV" style={{fontSize:"xx-large"}}>
          <p>With an understanding of your daily power consumption, we can accurately project your solar panel requirements to minimize generator dependency.</p>
          <div>
            <p className="descriptionRV">
              <strong>Example:</strong><br/>
              Consider Camper Ed, who consumes approximately 400Ah daily and seeks the freedom to boondock for extended periods. To meet his energy needs solely through solar power, the number of required solar panels can be calculated, factoring in variables such as location and weather conditions.
            </p>
            <div className="imagesRV">
              <img src={RV7} alt="Solar energy calculation example"/>
              <img src={RV8} alt="Solar panel efficiency chart"/>  
            </div>
          </div>

          <p className="formula">
            The formula for calculating the required wattage: <br/>
            400Ah x 12.8V = 5120Wh
          </p>
          <p>
            This watt-hour (Wh) value is then divided by the output of a typical 200W solar panel, adjusted for average sunlight hours and efficiency loss due to panel tilt.
          </p>
          <p className="formula">5120Wh / (200W x 5hr x 90%) ≈ 6 Panels</p>
          <p>
            <strong>Note:</strong> This calculation is based on peak sunlight hours and does not account for the gradual rise and set of the sun.
          </p>
          
          <div>
            <p className="descriptionRV">
              Adjustments are made for different locations, such as northern regions, where solar power yield may decrease due to the angle of sunlight and the need for air conditioning is reduced.
            </p>
            <div className="imageRV">
              <img src={RV9} alt="Solar power regional variation chart"/>
              <figcaption><em>Figure 1: Global Horizon Solar Irridance Map</em></figcaption>              
            </div>
          </div>
          
          <p className="formula">(300Ah x 12.8V) / (200W x 4hr x 60%) = 8 Panels</p>
          
          <div className="imageRV">
            <p className="descriptionRV">
              With optimal weather conditions, Ed could sustain his boondocking lifestyle with 8 solar panels, minimzing the need for his generator.
            </p>
            <img src={RV10} alt="Complete solar setup for boondocking"/>
          </div>
        </div>
      </div>

    )
}

export default Q1And2Conclusion;