import React from "react";
import BGT1 from '../education-images/BatteriesGT1.jpg';

const BatterySystem = () => {

    return(
        <div className="BGT banner2BGT">
            <h1>What Your System Will Look Like</h1>
            <img src={BGT1} alt="pic"/>
            <figcaption><i>Figure 1. Home Battery</i></figcaption>
            <h3>During the Day, the System will Charge your Batteries.</h3>
            <h3>
                Once night comes around, Your system will use your Batteries 
                to supply power to your Home.
            </h3>
            <h3>
                Once your battery hits a certain percentage, the system will 
                switch back to Grid Power to power the house.
            </h3>
        </div>
    )
}

export default BatterySystem;