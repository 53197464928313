import React from 'react';
import '../App.css'
import { useNavigate } from 'react-router-dom';
import logo from '../icon.png';


const Logo = () => {

    const navigate = useNavigate();

    const handleLogoClick = () => {
        navigate("/");
    }

    return(
      <img 
        src={logo} 
        alt="pic" 
        className="logo" 
        onClick={handleLogoClick} 
      />
    )
}

export default Logo;