import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCart } from "../../Redux_Toolkit/cartSlice";
import { motion } from "framer-motion";

const quantity = []
for(let i = 1; i < 10; i++){
    quantity.push(i);
}

const Product = () => {
  const [amount, setAmount] = useState(1);
  const [currentImage, setCurrentImage] = useState(0);
  const location = useLocation();
  const product = location.state;
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const [popupClass, setPopupClass] = useState('hide');
  const dispatch = useDispatch();

  const handleAddToCart = (product) => {
    dispatch(setCart({
      product:product.productName,
      quantity: amount,
      productType:product.Category,
      quantityChange: "add",
    }))
    setShowPopup(true);
    setPopupClass('show');
  }

  useEffect(() => {
  if (showPopup) {
    setTimeout(() => {
      setPopupClass('hide');
      setShowPopup(false);
    }, 3000)
  }
}, [showPopup])

  useEffect(() => {
    setTimeout(() => {
      document.getElementById("cart").scrollIntoView({
        behavior: "smooth"
      });
    }, 100);
  }, [product])

  const handleBackButton = () => {
      navigate(-1);
  }

  function removeSpaces(str) {
    return str.replace(/[\s-,/]+/g, '');
  }
    
  return (
    <div className="ProductPage">
      <div className={`popup ${popupClass}`}>
        An item has been added to the Cart!
      </div>
      <button id="cart" className="backButton" onClick={handleBackButton}>Back</button>
      {product ? (
        <>
          <h1 className="productNameMobile">{product.productName}</h1>
          <motion.div 
            className="triSection"
            initial={{ opacity: 0}}
            animate={{ opacity: 1}}
            onAnimationComplete={() => {
              document.getElementById('cart').scrollIntoView({
                behavior:'smooth'
              })
            }}
          >
          <div className="leftInTri">
            <div className="productMainImage">
              <img 
                  src={product.images[currentImage]} 
                  alt={product.productName}
              />                        
            </div>
            <div className="productSubImage">
              {Object.values(product.images).map((image, index) => (
                <div className={`productSubImageContainer ${currentImage===index ? "activeImage" : ""}`} onClick= {(e) => setCurrentImage(index)}>
                  <img
                    key={index}
                    src={image} 
                    alt={product.productName} 
                    onClick = {(e) => {
                        setCurrentImage(index)
                    }}
                  />                                
                </div>
              ))}                        
            </div>

            <div className="rightInTriMobile">
              <h2><span className="dollarSign">$</span>{product.retail}</h2>
              <select onChange={(e) => setAmount(e.target.value)}>
              {quantity.map((value) => (
                <option key={value} value={value}>Quantity: {value}</option>
              ))}
              </select>
              <button onClick={() => handleAddToCart(product)} className="addToCart">Add To Cart</button>
            </div>

            </div>
            <div className="midInTri" >
              <h1 className="productNameDesktop">{product.productName}</h1>
              <h2>SKU: {product.SKU}</h2>
              {product.NEMA ? <h2>NEMA {product.NEMA}</h2> : ""}
              <h2>{product.type}</h2>
              {product.voltage ? <h2>DC Voltage: {product.voltage}</h2> : ""}
              {product.VA ? <h2>VA: {product.VA}</h2> : ""}
              {product.dimension ? <h2>Dimensions (H x W x D)(Inches):<br/> {product.dimension}</h2> : ""}
              {product.weight ? <h2>Weight (lbs): {product.weight}</h2> : ""}
              <br/>
              <h1>Resources</h1>
              {product.datasheet ? <h2><a href={product.datasheet} target="blank">Datasheet</a></h2> : ""}
              {product.victronPage ? <h2><a href={product.victronPage} target="blank">Victron's Website</a></h2> : ""}
              {product.manual ? <h2><a href={product.manual} target="blank">Manual</a></h2> : ""}
              
            </div>
            <div className="rightInTri">
              <h2><span className="dollarSign">$</span>{product.retail}</h2>
              <select onChange={(e) => setAmount(e.target.value)}>
              {quantity.map((value) => (
                <option key={value} value={value}>{value}</option>
              ))}
              </select>
              <h3>Subtotal: ${`${removeSpaces(product.retail) * amount}`}</h3>
              <button onClick={() => handleAddToCart(product)} className="addToCart">Add To Cart</button>
            </div>
          </motion.div>        
        </>

    ) : (
        <div>
            <h2>Oops, looks there's a problem?</h2>
        </div>
      )}
    </div>
  )
}

export default Product