import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setCart } from "../../Redux_Toolkit/cartSlice";

const Category = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [popupClass, setPopupClass] = useState('hide');
  const [toggleMoreSort, setToggleMoreSort] = useState(false);
  const [sortOption, setSortOption] = useState("A - Z");
  const [sortedDisplayCategory, setSortedDisplayCategory] = useState([]);
  const [filteredCategory, setFilteredCategory] = useState([]);
  const [category, setCategory] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const db = useSelector((state) => state.firebaseDB.products);
  const [page, setPage] = useState(1);
  const [seriesFilter, setSeriesFilter] = useState(["All"]);
  const [selectedOption, setSelectedOption] = useState(seriesFilter[0])
  const [toggleSeriesFilter, setToggleSeriesFilter] = useState(false);
  const { categoryId } = useParams();
  const [startValue, setStartValue] = useState(0);
  const [endValue, setEndValue] = useState(0);
  const [numOfPages, setNumOfPages] = useState(0);
  const [pagesArray, setPagesArray] = useState([]);

  useEffect(() => {
    if (db && db[categoryId] && typeof db[categoryId] === 'object') {
      const initialCategory = Object.entries(db[categoryId]);
      const sortedCategory = initialCategory.sort((a, b) => a[0].localeCompare(b[0]));
      setCategory(sortedCategory);
    } else {
      setCategory([]);
    }
  }, [db, categoryId]);

  useEffect(() => {
    setStartValue(() => page * 15 - 15);
    setEndValue(() => page * 15);
    setNumOfPages(() => Math.ceil(filteredCategory.length/ 15));
    setPagesArray(() => Array.from({length: numOfPages}, (_, index) => index + 1));
  }, [page, filteredCategory, numOfPages])
  
  useEffect(() => {
    if (category.length) {
      let newSeriesFilter = ["All"];
      let newFilteredCategory = [];

      category.forEach((product) => {
        if (!newSeriesFilter.includes(product[1]["Series"])) {
          newSeriesFilter.push(product[1]["Series"]);
        }
      });

      if (selectedOption !== "All") {
        newFilteredCategory = category.filter(
          (product) => selectedOption === product[1]["Series"]
        );
      } else {
        newFilteredCategory = [...category];
      }

      if (JSON.stringify(newSeriesFilter) !== JSON.stringify(seriesFilter)) {
        setSeriesFilter(newSeriesFilter);
      }

      if (JSON.stringify(newFilteredCategory) !== JSON.stringify(filteredCategory)) {
        setFilteredCategory(newFilteredCategory);
      }
    }
  }, [selectedOption, seriesFilter, category, filteredCategory]);

  if(!seriesFilter.includes(selectedOption)){
    setSelectedOption("All");
  }

  const handleRadioChange = (e) => {
    setSelectedOption(e.target.value);
    setPage(1);
  }

  const handleAddToCart = (product) => {
    dispatch(setCart({
        product:product.productName,
        quantity: 1,
        productType:product.Category,
        quantityChange: "add",
    }))
    setShowPopup(true);
    setPopupClass('show');
  }

  const handleMoreInfoButton = (product) => {
    navigate(`../product/${removeSpaces(product.productName)}`, {state: {...product}});
  }

  function removeSpaces(str) {
    return str.replace(/[\s,-/]+/g, '');
  }

  useEffect(() => {
    if (showPopup) {
        setTimeout(() => {
            setPopupClass('hide');
            setShowPopup(false);
        }, 3000)
    }
  }, [showPopup])

  useEffect(() => {
    setPage(1);
  }, [location])

  useEffect(() => {
    let sortedCategory = [...filteredCategory]; // Create a new array to avoid direct mutation

    switch (sortOption) {
      case "A - Z":
        sortedCategory.sort((a, b) => a[0].localeCompare(b[0]));
        break;
      case "Z - A":
        sortedCategory.sort((a, b) => b[0].localeCompare(a[0]));
        break;
      case "Lowest - Highest Cost":
        sortedCategory.sort((a, b) => removeSpaces(a[1].retail) - removeSpaces(b[1].retail));
        break;
      case "Highest - Lowest Cost":
        sortedCategory.sort((a, b) => removeSpaces(b[1].retail) - removeSpaces(a[1].retail));
        break;
      default:
        sortedCategory.sort((a, b) => a[0].localeCompare(b[0]));
    }

    setSortedDisplayCategory(sortedCategory.slice(startValue, endValue));
  }, [sortOption, filteredCategory, startValue, endValue]);

  const handleSeriesToggle = () => {
    setToggleSeriesFilter(!toggleSeriesFilter);
  }

  return (
    <>
      <div className={`popup ${popupClass}`}>
        <p>An item has been added to the Cart!</p>
        <p>Go To Cart To Checkout!</p>
      </div>
      
      <div className="seriesFilterTitle" onClick={() => handleSeriesToggle()}>
        <h2 id="seriesFilter" className="seriesFilterH2">Series Filter</h2>
        <h2 className="toggleSwitch">{toggleSeriesFilter ? "▲" : "▼"}</h2>        
      </div>

      <div className={toggleSeriesFilter ? "radioFilterMobile" : "hideSeriesMenu"}>
        {seriesFilter.map((option) => (
          <label key={option} className="radioFilterOptions">
            <input
              type="radio"
              value={option}
              checked={selectedOption === option}
              onChange={handleRadioChange}
              onClick={() => document.getElementById("page").scrollIntoView({
                behavior:"smooth",
              })}
            />
            {option}
          </label>
        ))}
        <div className="moreSorting">
          {toggleMoreSort ? 
          <div className="moreSortingOpen">
            <button onClick={() => setToggleMoreSort(false)}>✕</button>
            <select onChange={(e) => setSortOption(e.target.value)}>
              <option>A - Z</option>
              <option>Z - A</option>
              <option>Lowest - Highest Cost</option>
              <option>Highest - Lowest Cost</option>
            </select>
          </div>
          :
          <button onClick={() => setToggleMoreSort(true)}>More Sorting Options</button>}
        </div>
      </div>

      <div className="radioFilter">
        {seriesFilter.map((option) => (
          <label key={option} className="radioFilterOptions">
            <input
              type="radio"
              value={option}
              checked={selectedOption === option}
              onChange={handleRadioChange}
            />
            {option}
          </label>
        ))}
        <div className="moreSorting">
          {toggleMoreSort ? 
          <div className="moreSortingOpen">
            <button onClick={() => setToggleMoreSort(false)}>✕</button>
            <select onChange={(e) => setSortOption(e.target.value)}>
              <option>A - Z</option>
              <option>Z - A</option>
              <option>Lowest - Highest Cost</option>
              <option>Highest - Lowest Cost</option>
            </select>
          </div>
          :
          <button onClick={() => setToggleMoreSort(true)}>More Sorting Options</button>}
        </div>
      </div>

      <div className="page" id="page">
        {pagesArray.map(value => (
          <button id={page === value ? "activePage" : ""} key={value} value={value} onClick={() => setPage(value)}>{value}</button>
        ))}        
      </div>
      {sortedDisplayCategory.map(([key, product], index) => (
        <div key={key} className="productForSale">
          <div className="productImageContainer">
            <img src={product.frontImg} alt="product" />
          </div>
          <div className="productDescription">
            <h2>{key}</h2>
            <div className="doubleContainer">
              <div className="left">
                <h3>{product.type}</h3>
                {product.voltage ? <h3>Volts:{product.voltage}</h3> : ""}
                {product.VA ? <h3>Rated Power:{product.VA}VA</h3> : ""}
              </div>
              <div className="right">
                <h2 className="price"><span className="dollarSign">$</span>{product.retail}</h2>
              </div>
            </div>
          </div>
          <div className="optionsForSale">
            <button key={key} onClick={() => handleMoreInfoButton(product)}>More Info</button>
            <button onClick={() => handleAddToCart(product)} className="addToCart">Add to Cart</button>
          </div>
        </div>
      ))}
      <div className="page">
        {pagesArray.map(value => (
          <button 
            id={page === value ? "activePage" : ""} 
            key={value} value={value} onClick={() => setPage(value)}>
            {value}
          </button>
        ))}        
      </div>        
    </>
  )
}

export default Category;