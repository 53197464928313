import React from "react";

const Benefits = () => {
    return(
      <div className="banner4OG">
        <h1>Going Off-Grid with Simple Indy</h1>
        <div className="doubleContainer">
          <section className="leftContainer">
            <h2>Our Commitment to You</h2>
            <h3>
              At Simple Indy, we understand the significance of 
              transitioning to off-grid living. We provide:
            </h3>
            <ul>
              <li>
                <h3>Tailored Solutions:</h3>
                <p>
                  Customized systems to meet your unique energy needs.
                </p>
              </li>
              <li>
                <h3>Quality and Reliability:</h3>
                <p>
                  Installation of high-quality solar panels and generators.
                </p>
              </li>
              <li>
                <h3>Ongoing Support:</h3>
                <p>
                  Maintenance services and expert advice 
                  for optimal system performance.
                </p>
              </li>
            </ul>
          </section>  
          <section className="rightContainer">
            <h2>Thoughtful Consideration for a Sustainable Future</h2>
            <h3>
              While transitioning to an off-grid system requires an initial 
              investment, the long-term benefits of energy independence and reduced 
              utility bills make it worthwhile.
            </h3>
            <ul>
              <li>
                <h3>Initial Investment:</h3>
                <p>
                  Transitioning to an off-grid system is an investment in your 
                  energy independence and sustainability. While there's an initial 
                  cost involved, the long-term benefits of self-sufficiency and reduced 
                  utility bills make it a worthwhile commitment for a greener future.
                </p>
              </li>
              <li>
                <h3>Maintenance Matters:</h3>
                <p>
                  Like any energy system, off-grid setups require 
                  regular maintenance. Our team is here to assist 
                  with all your maintenance needs, ensuring longevity 
                  and efficiency.
                </p>
              </li>
              <li>
                <h3>Space Efficiency:</h3>
                <p>
                  Solar panels require space for optimal installation. 
                  Our experts will help identify the best location to 
                  maximize energy production.
                </p>
              </li>
            </ul>
          </section>           
        </div>
      </div>
    )
}

export default Benefits;