import React from 'react';
import { motion } from 'framer-motion';

import BatteryInfo from './children-BatteriesGT/BatteryInfo.js';
import BatterySystem from './children-BatteriesGT/BatterySystem.js';
import BenefitsOfBatteries from './children-BatteriesGT/BenefitsOfBatteries.js';
import Products from './children-BatteriesGT/Products.js';
import HowManyBatteries from './children-BatteriesGT/HowManyBatteries.js';
import Next from './children-BatteriesGT/Next.js';

const BatteriesGT = () => {

  const content = [
    BatteryInfo, 
    BatterySystem, 
    BenefitsOfBatteries,
    HowManyBatteries,  
    Products,
    Next
  ]

  const containerVariants = {
      hidden: { opacity: 1 },
      visible: {
        opacity: 1,
        transition: {
          // This will stagger the children's individual animations by 0.3 seconds.
          staggerChildren: 0.3
        }
      }
    };

  const childVariants = {
      hidden: { opacity: 0 },
      visible: { opacity: 1 }
  };

    return(
    <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        className="bannerContainer"
    >
      {content.map((Content, index) => (
        <motion.div
          variants={childVariants}
          transition = {{duration:0.3}}
          onAnimationComplete={index===0 ? () => {
            document.getElementById('gridTiedBatteries').scrollIntoView({
              behavior:'smooth'
            })
          } : undefined}
          className={`container${index % 2 + 1}`}
          style={{
            "borderRadius":"15px",
            "fontSize":"x-large"
          }}
        >
          <Content />
        </motion.div>
      ))}
    </motion.div>
    )
}

export default BatteriesGT;