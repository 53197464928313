import React from "react";

const Ad = () => {
  return(
    <div className="productsBanner ad">
      <h1>10% off orders over $3,000</h1>
      <h1>15% off orders over $5,000</h1>
      <h1>Tech Support available with purchase</h1>
    </div>
  )
}

export default Ad