import groundMountPicture from './quote-images/QR-3.jpg';
import roofMountPicture from './quote-images/QR-2.jpg';

const utilityCostOptions = [];
for (let i = 0; i <= 300; i += 10) {
    utilityCostOptions.push(i);
}

const batteryBackUpHours = [];
for (let i = 0; i <= 48; i += 1) {
  batteryBackUpHours.push(`${i} Hours`);
}

const utilityCompanies = [
  "---Select Utility---",
  "AES Indiana",
  "CenterPoint Energy",
  "Duke Energy Indiana",
  "Hoosier Energy",
  "Indiana Electric Cooperatives",
  "Indiana Michigan Power (I&M)",
  "Indiana Municipal Power Agency (IMPA)",
  "Northern Indiana Public Service Company (NIPSCO)",
  "Wabash Valley Power Alliance"    
]

const roofTypes = ["Shingles", "Metal", "Flat Rubber"];

const residentialQuestionsData = [
  {
    id: 'address',
    type: 'address',      
    text: 'Installation Address',
  },
  {
    id: 'electricitybill',
    type: 'select',      
    text: 'On average, how much is your Electricity Bill?',
    label: 'Round the cost to the nearest 10s',
    select: utilityCostOptions.map((option, index) => (
      <option key={index} value={option}>${option}</option>
    )),
  },
  {
    id: 'utility',
    type: 'select',      
    text: 'Who is your Utility Company?',
    label: 'Select Your Electric Utility Company',
    select: utilityCompanies.map((option, index) => (
      <option key={index} value={option}>{option}</option>
    )),
  },
  {
    id: 'offset',
    type: 'multipleChoice',      
    text: 'How much do you want to offset your utility bill?',
    select: ["50%", "75%", "100%"]
  },
  {
    id:'mount',
    type:'multipleChoiceWithImages',      
    text: "Are we looking for Ground Mount or Roof Mount?",
    text2: "(Click both if you are interested in doing a combination of both)",
    select:[
      {displayValue:"Ground Mount", value:"ground_mount"},
      {displayValue:"Roof Mount", value: "roof_mount"}
    ],
    images:[groundMountPicture, roofMountPicture],
    followUpRequiredValue: "roof_mount",
    followUp: {
      id:'type_of_roof',
      type: 'select',
      text: "What kind of roof are we working with?",
      select: roofTypes.map((option, index) => (
        <option key={index} value={option}>{option}</option>
      ))
    }
  },
  {
    id:'battery',
    type: 'multipleChoice',      
    text: "Want battery back up? (Highly Recommended)",
    select: ['Yes', 'No'],
    recommendation: "gridTiedBatteries",
    followUpRequiredValue: 'Yes',
    followUp: {
      id:'q5a',
      type: 'select',
      text: "How many hours worth of Battery Backup are we looking for?",
      select: batteryBackUpHours.map((option, index) => (
        <option key={index} value={option}>{option}</option>
      ))
    }
  },
  {
    id: 'preferredcommunication',
    type: 'multipleChoice',      
    text: 'Preferred form of Communication',
    select: ["Email", "Phone Call", "Text"],
    followUp: {
      text:"",
      id:null,
      type:null,
    }
  },
  {
    id: 'customerComment',
    type: 'input',
    text: "Please share any details that will help us provide you with the best possible service.",
  }
];

  
export default residentialQuestionsData;