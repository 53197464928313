import React from 'react';
import { motion } from 'framer-motion';

import TheWholeProcedure from './children-GridTied/TheWholeProcedure.js';
import Mounts from './children-GridTied/Mounts.js';
import System from './children-GridTied/System.js';
import Products from './children-GridTied/Products.js';
import UtilityWithSolar from './children-GridTied/UtilityWithSolar.js';
import Next from './children-GridTied/Next.js';

const GridTied = () => {

  const banners = [
    TheWholeProcedure, 
    Mounts, 
    System, 
    Products, 
    UtilityWithSolar, 
    Next,
  ];

    const containerVariants = {
        hidden: { opacity: 1 },
        visible: {
        opacity: 1,
        transition: {
            // This will stagger the children's individual animations by 0.3 seconds.
            staggerChildren: 0.3
        }
        }
    };

    const childVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 }
    };

    return(
    <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        className="bannerContainer"
      >

        {banners.map((Component, index) => (
          <motion.div
            variants={childVariants}
            transition={{ duration: 0.3 }}
            onAnimationComplete={index === 0 ? () => {
              document.getElementById('gridTied').scrollIntoView({
                behavior:'smooth'
              })
            } : undefined}
            className={`container${index % 2 + 1}`}
            style={{
            "borderRadius":"15px",
            "fontSize":"x-large",            
            }}
          >
            {index === 0 ? <h1 id="gridTied" className="gridTiedTitle">Grid Tied Systems</h1> : null}
            <Component />
          </motion.div>
        ))}
    </motion.div>
    )
}

export default GridTied;

