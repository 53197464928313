import React from "react";
import { Link } from "react-router-dom";

import GridTiedBatteries from '../education-images/GridTiedBattery.jpg';
import GetQuote from '../education-images/GetQuote.jpg';

const Next = () => {
    return(
            <div className="banner6GT">
                <h1>What's Next?</h1>
                <div className="options">
                <Link to="../gridTiedBatteries">
                    <div className="option">
                        <img src={GridTiedBatteries} alt="pic" />
                        <h4>Grid Tied w/ Batteries</h4>
                    </div>
                </Link>

                <Link to="../../Quote">
                    <div className="option">
                        <img src={GetQuote} alt="pic" />
                        <h3>Get a Quote?</h3>
                    </div>                  
                </Link> 
                </div>
            </div>
    )
}

export default Next;