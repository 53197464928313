import React from "react";

import RV1 from '../education-images/RV1.jpeg';
import RV15 from '../education-images/RV15.png';
import RV16 from '../education-images/RV16.webp';
import RV17 from '../education-images/RV17.webp';

const IntroToRv = () => {
    return(
      <div className="banner1RV">
        <h1 id="rv">Optimizing Your Energy System</h1>
        <img className="mainPic" src={RV1} alt="Energy system components" />
        <h2>Current System Components</h2>
        <ul>
          <li>Choice of Inverter + Converter or Integrated Inverter/Charger</li>
          <li>Selection between Lead Acid or Lithium Batteries</li>
        </ul>
        <h2>Possible Enhancements for Efficiency and Reliability</h2>
        <div className="doubleContainer">
          <section className="leftContainer">
            <ul>
              <li>
                <h3>Victron Energy - MultiPlus Inverter/Charger Series</h3>
                <p>Experience seamless power with no flickering, backed by comprehensive support and an extensive 5-year warranty.</p>
              </li>
              <li>
                <h3>Advanced Lithium Battery Technology</h3>
                <p>Enjoy a lightweight power solution, with lithium batteries offering peak performance compared to that of traditional lead-acid batteries.</p>
              </li>
              <li>
                <h3>High-Efficiency Solar Panels with Victron Energy MPPT</h3>
                <p>
                  Harness solar energy effectively, reducing generator runtime with smart MPPT charge controllers for optimal battery charging.
                </p>                
              </li>
              <li>
                <h3>Victron Energy Remote Monitoring System</h3>
                <p>
                  Stay connected to your energy system with Victron's cutting-edge remote monitoring technology, offering global system oversight via the internet.
                </p>
              </li>
            </ul>
          </section>
          <section className="rightContainer">
            <img src={RV15} alt="pic" />
            <img src={RV16} alt="pic" />
            <img src={RV17} alt="pic" />
          </section>
        </div>
      </div>
    )
}

export default IntroToRv;