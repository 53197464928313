import solar from './quote-images/QRV-2.jpg';
import battery from './quote-images/QRV-3.png';
import system from './quote-images/QRV-4.png';

function getNext12Months() {
  const currentDate = new Date();
  let months = [];

  for (let i = 0; i <= 12; i++) {
    // Create a new date for each month
    let futureMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + i, 1);
    
    // Format the month and year
    let month = (futureMonth.getMonth() + 1).toString().padStart(2, '0');
    let year = futureMonth.getFullYear();
    
    // Add to the months array
    months.push(`${month}/${year}`);
  }

  return months;
}

const next12Months = getNext12Months();


const rvQuestionsData = [
  {
    id: 'address',
    type: 'address',      
    text: 'Installation Address',
  },
  {
    id:'installation',
    type:'multipleChoiceWithImages',
    text: "What service are we looking for? (Choose any combination of the following options)",
    select:[
      {displayValue:"Solar", value:"solar"},
      {displayValue:"Lithium Batteries", value: "lithium_batteries"},
      {displayValue:"System Upgrade (Victron)", value: "system_upgrade"}
    ],
    images:[solar, battery, system],
    special: "batteryRecommendation",
    className: "installation"
    //Please refer to Question.js (RvBatteryRecommendation) for the conditional follow-up questions
  },
  {
    id: 'coachModel',
    type: 'textInput',
    text: "What is the model of your RV/Camper?"
  },
  {
    id: 'preferredMonthOfInstallation',
    type: 'select',
    text: 'Which month do you prefer having your installation done? (Our salesman will contact you to get things ironed out after)',
    select: next12Months.map((month, index) => (
      <option value={month} key={index}>{month}</option>
    ))
  },
  {
    id: 'preferredcommunication',
    type: 'multipleChoice',      
    text: 'Preferred form of Communication',
    select: ["Email", "Phone Call", "Text"],
    followUp: {
      text:"",
      id:null,
      type:null,
    }
  },
  {
    id: 'customerComment',
    type: 'input',
    text: "Please share any details that will help us provide you with the best possible service.",
  }
];
export default rvQuestionsData;