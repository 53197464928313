import React, { useState, useEffect, useMemo, useRef} from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { clearCart } from "../../Redux_Toolkit/cartSlice";

/* import mastercard from './images-products/mc_symbol.png';
import americanExpress from './images-products/American_Express-Logo.wine.png';
import discover from './images-products/discover_symbol.png';
import visa from './images-products/Visa_Brandmark.png'; */


const Checkout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const db = useSelector((state) => state.firebaseDB.products);
  

  const [toggleShipping, setToggleShipping] = useState(true);
/*   const [togglePayment, setTogglePayment] = useState(false); */
/*   const [toggleBillingAddress, setToggleBillingAddress] = useState(true); */
  const [toggleEmail, setToggleEmail] = useState(false);
  const [upsRates, setUpsRates] = useState(0);
  const [upsExtraBoxRates, setUpsExtraBoxRates] = useState(0);
  const [victronFreightRates, setVictronFreightRates] = useState(0); 
  const [customerEmail, setCustomerEmail] = useState("");
  const [discount, setDiscount] = useState(0);
  const [total, setTotal] = useState(0);
  const [shippingBoxSize, setShippingBoxSize] = useState("");
  const [boxTooBig, setBoxTooBig] = useState(false);
  const [upsWeight, setUpsWeight] = useState(0);
  const [shippingWeight, setShippingWeight] = useState(0);
  const [freight, setFreight] = useState(false);
  const [haveBatteries, setHaveBatteries] = useState(false);
  const [needExtraBox, setNeedExtraBox] = useState(false);
  const [totalShippingCost, setTotalShippingCost] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingUPS, setIsLoadingUPS] = useState(false);
  const [checkForms, setCheckForms] = useState({
    shipping: false,
    /* payment: false, */
    email:false
  })
  const [shippingAddress, setShippingAddress] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    address: "",
    aptSuit: "",
    city: "",
    state: "",
    zip: "",
  })
/*   const [paymentInformation, setPaymentInformation] = useState({
    cardNumber: "",
    expMonth: "",
    expYear: "",
    CSC: ""
  }) */
/*   const [billingAddress, setBillingAddress] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    address: "",
    aptSuit: "",
    city: "",
    state: "",
    zip: "",
  }) */

  const categories = Object.entries(db).map(([key, value]) => ({ key, value }));

  const cartEntries = useMemo(() => {
    return cart.cart.length > 0 ? cart.cart.map(cartItem => {
      // Find the category index
      const categoryIndex = categories.findIndex(category => category.key === cartItem.productType);
      if (categoryIndex === -1) {
        return []; // Or handle the missing category case
      }
    
      const category = categories[categoryIndex].value;
    
      // Find the product in the category
      const productKey = Object.keys(category).find(key => key === cartItem.product);
      const product = category[productKey];
    
      if (!product) {
        return []; // Or handle the missing product case
      }
    
      return {
        product,
        quantity: cartItem.quantity,
        productName: cartItem.product,
        productDimensions: product.dimension,
      };
    }) : [];
  }, [cart, categories]);  // Dependencies array

  var years = [];
  var currentYear = new Date().getFullYear(); // Get the current year
  for (var i = 0; i <= 20; i++) {
    var year = currentYear + i;
    var option = document.createElement('option');
    option.value = option.textContent = year;
    years.push(year);
  }

  const stateAbbreviations = [
    "AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA",
    "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD",
    "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ",
    "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC",
    "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY"
  ];

  const handleDeleteFromCart = () => {
    dispatch(clearCart())
  }

  const handleSubmitShippingAddress = async (e) => {
    e.preventDefault();
    let dataToSend;
    if(upsWeight > 50 && upsWeight < 140){
      dataToSend = dataForUpsStandardRates;
    } else dataToSend = dataForUpsSimpleRates;
    let addressValid = false;
    setIsLoadingUPS(true);
    await fetch('/.netlify/functions/fetchUpsRates', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(dataToSend),
    }) // Ensure the path starts with '/' to make it an absolute path
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json(); // Parse the JSON from the response
    })
    .then(data => {
      addressValid = true;
      setUpsRates(data.RateResponse.RatedShipment.TotalCharges.MonetaryValue);
      setToggleEmail(true);
    })
    .catch(error => {
      console.error("Error fetching UPS rates:", error);
      addressValid = false;
    })
    .finally(() => {
      setIsLoadingUPS(false);
    });

    for(let i = 0; i < 10; i++){
      if(extraBoxes.current[i]){
        const dataForExtraBoxes = {
          "RateRequest": {
            "Request": {
              "RequestOption": "Rate",
              "TransactionReference": {
                "CustomerContext": "CustomerContext"
              }
            },
            "Shipment": {
              "Shipper": {
                "Name": "Simple Indy LLC",
                "ShipperNumber": "H5514V",
                "Address": {
                  "AddressLine": [
                    "240 S. Chester Ave",
                    "ShipperAddressLine",
                    "ShipperAddressLine"
                  ],
                  "City": "INDIANAPOLIS",
                  "StateProvinceCode": "IN",
                  "PostalCode": "46201",
                  "CountryCode": "US"
                }
              },
              "ShipTo": {
                "Name": `${shippingAddress.firstName} ${shippingAddress.lastName}`,
                "Address": {
                  "AddressLine": [
                    shippingAddress.address,
                    shippingAddress.aptSuit,
                    "ShipToAddressLine"
                  ],
                  "City": shippingAddress.city,
                  "StateProvinceCode": shippingAddress.state,
                  "PostalCode": shippingAddress.zip,
                  "CountryCode": "US"
                }
              },
              "ShipFrom": {
                "Name": "Simple Indy LLC",
                "Address": {
                  "AddressLine": [
                    "240 S. Chester Ave",
                    "ShipFromAddressLine",
                    "ShipFromAddressLine"
                  ],
                  "City": "INDIANAPOLIS",
                  "StateProvinceCode": "IN",
                  "PostalCode": "46201",
                  "CountryCode": "US"
                }
              },
              "PaymentDetails": {
                "ShipmentCharge": [{
                  "Type": "01",
                  "BillShipper": {
                    "AccountNumber": "H5514V"
                  }
                }]
              },
              "Service": {
                "Code": "003",
                "Description": "Ground"
              },
              "NumOfPieces": "1",
              "Package": [{
                "SimpleRate": {
                  "Description": "SimpleRateDescription",
                  "Code": extraBoxes.current[i],
                },
                "PackagingType": {
                  "Code": "02",
                  "Description": "Packaging"
                },
                "Dimensions": {
                  "UnitOfMeasurement": {
                    "Code": "IN",
                    "Description": "Inches"
                  },
                  "Length": allDimensions.current,
                  "Width": allDimensions.current,
                  "Height": allDimensions.current,
                },
                "PackageWeight": {
                  "UnitOfMeasurement": {
                    "Code": "LBS",
                    "Description": "Pounds"
                  },
                  "Weight": upsWeight.toFixed(0)
                }
              }]
            }
          }
        }
        await fetch('/.netlify/functions/fetchUpsRates', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(dataForExtraBoxes),
        }) // Ensure the path starts with '/' to make it an absolute path
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json(); // Parse the JSON from the response
        })
        .then(data => {
          setUpsExtraBoxRates((prev) => prev + (data.RateResponse.RatedShipment.TotalCharges.MonetaryValue));
        })
        .catch(error => {
          console.error("Error fetching UPS rates:", error);
        });        
      }
    }


    if(!addressValid){
      alert("Address is Invalid \n Please Double Check your Address")
      return;
    }
    setToggleShipping(false);
    setCheckForms((prev) => ({...prev, shipping: true}))
/*     setTogglePayment(true); */
  }

/*   const handleSubmitPayment = (e) => {
    e.preventDefault();
    setTogglePayment(false);
    setCheckForms(prev => ({...prev, payment: true}))
    setToggleEmail(true);
  }

  const handleBillingEqualShipping = (e) => {
    setToggleBillingAddress(!toggleBillingAddress);
    setBillingAddress(shippingAddress)
  } */

  const handleSubmitEmail = (e) => {
    e.preventDefault();
    setToggleEmail(false);
    setCheckForms(prev => ({...prev, email:true}))
  }

  const handleCheckOut = async () => {
    const cartDataToSend = cartEntries.map(product => {
      return {
        product: product.product,
        quantity: product.quantity,
      }
    })
    cartDataToSend.push({shipping: totalShippingCost})
    const dataToSend = {
      email: customerEmail,
      displayName: `E-Store ${shippingAddress.firstName} ${shippingAddress.lastName}`,
      givenName: shippingAddress.firstName,
      familyName: shippingAddress.lastName,
      phoneNumber: shippingAddress.phone,
      state: shippingAddress.state,
      city: shippingAddress.city,
      postal: shippingAddress.zip,
      addressLine1: shippingAddress.address,
      addressLine2: shippingAddress.aptSuit,
      country: "USA",
      cart: cartDataToSend,
      total: total.toFixed(2),
    }
    setIsLoading(true);
    try {
      const response = await fetch('/.netlify/functions/checkout', {
        method: 'POST',
        headers:{
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(dataToSend)
      });
      if (!response.ok){
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const responseData = await response.json();
      console.log(responseData);
      handleDeleteFromCart();
      navigate('../confirmation')
      return responseData;
    } catch (error){
      console.error('Request failed', error);
      return null;
    } finally {
      setIsLoading(false);
    }
  }


  //Sets Billing Address = Shipping Address
/*   useEffect(() => {
    if(toggleBillingAddress === false){
      setBillingAddress(shippingAddress)
    }
  }, [shippingAddress, toggleBillingAddress]) */

  //Makes sure Customer goes through each Step
  useEffect(() => {
    if(toggleShipping){
      setCheckForms((prev) => ({...prev, shipping: false}))
    }
/*     if(togglePayment){
      setCheckForms((prev) => ({...prev, payment:false}))
    } */
    if(toggleEmail){
      setCheckForms((prev) => ({...prev, email:false}))
    }
  }, [/* togglePayment, */ toggleShipping, toggleEmail])

  let subTotal = 0;
  cartEntries.forEach(product => {
    const quantity = product.quantity;
    const price = removeCommas(product.product.retail);
    subTotal = subTotal + (quantity * price)
  });

  //Determine discount
  useEffect(() => {
    if(subTotal < 3000){
      setDiscount(0);
    }
    if(subTotal >= 3000 && subTotal < 5000){
      setDiscount(() => subTotal * 0.1);
    }
    if(subTotal >= 5000){
      setDiscount(() => subTotal * 0.15);
    }
  }, [subTotal])
  
  //Determine total
  useEffect(() => {
    setTotal(parseFloat(subTotal) + parseFloat(totalShippingCost) - parseFloat(discount));
  }, [subTotal, totalShippingCost, discount])

  //Check if Cart has Batteries
  useEffect(() => {
    let categories = cartEntries.map(product => {
      return product.product.Category;
    })
    if(categories.includes("Batteries")){
      setHaveBatteries(true);
    }
  }, [cartEntries])

  //Handle Victron Freight Rates
  useEffect(() => {
    if((shippingWeight > 50 && subTotal > 5000) || haveBatteries){
      setFreight(true);
      setVictronFreightRates(() => {
        const cost = 0.3262 * shippingWeight + 141.2711;
        const cost2 = cost * 1.5;
        return cost2;
      })
    } else setFreight(false);
  }, [shippingWeight, subTotal, haveBatteries])

  //Calculates Volume and Determine Shipping Box Size
  let allDimensions = useRef({
    totalLength: 0,
    totalWidth: 0,
    totalHeight: 0,
  })
  let extraBoxes = useRef(new Array(10));
  useEffect(() => {
    let compensatedVolumes = 0;
    const volumes = cartEntries.map(product => {
      if(!product.productDimensions){
        compensatedVolumes += 100;
      }
      let quantity = product.quantity;
      let [a, b, c] = product.productDimensions.split(' x ').map(Number);
      let volume = ((a || 3) * (b || 3) * (c || 2) * quantity).toFixed(2);
      return volume
    });
    const sumOfVolumes = volumes.reduce((acc, cur) => {
      return parseFloat(acc) + parseFloat(cur);
    }, 0)
    const totalVolume = sumOfVolumes + compensatedVolumes;
    switch(true) {
      case (totalVolume < 100):
        setShippingBoxSize("XS");
        setBoxTooBig(false);
        break;
      case (totalVolume < 216):
        setShippingBoxSize("S"); // 6 x 6 x 6 ups box
        setBoxTooBig(false);
        break;
      case (totalVolume < 512):
        setShippingBoxSize("M"); //8 x 8  x 8 ups box
        setBoxTooBig(false);
        break;
      case (totalVolume < 1000):
        setShippingBoxSize("L"); //10 x 10 x 10 ups box
        setBoxTooBig(false);
        break;
      case (totalVolume < 1725):
        setShippingBoxSize("XL"); //12 x 12 x 12 ups box
        setBoxTooBig(false);
        break;
      default:
        setBoxTooBig(true);
        setShippingBoxSize("XL") 
        break;
    }
    if(boxTooBig && upsWeight < 50){
      setNeedExtraBox(true);
      const times = Math.floor((totalVolume - 1725) / 1725);
      const remainder = totalVolume % 1725;
      for(let i = 0; i < times; i++){
        extraBoxes.current[i] = "XL";
      }
      switch(true) {
        case (remainder < 100):
          extraBoxes.current[times + 1] = "XS";
          break;
        case (remainder < 216):
          extraBoxes.current[times + 1] = "S"; // 6 x 6 x 6 ups box
          break;
        case (remainder < 512):
          extraBoxes.current[times + 1] = "M";; //8 x 8  x 8 ups box
          break;
        case (remainder < 1000):
          extraBoxes.current[times + 1] = "L"; //10 x 10 x 10 ups box
          break;
        case (remainder < 1725):
          extraBoxes.current[times + 1] = "XL"; //12 x 12 x 12 ups box
          break;
        default:
          break;
      }
    }
    
    const cubeRootValue = Math.cbrt(totalVolume).toFixed(2);
    allDimensions.current = cubeRootValue;
  }, [cartEntries, boxTooBig, upsWeight])

  //Determine Weight
  useEffect(() => {
    if(cartEntries.length > 0){
      let weights = cartEntries.map((product) => {
        return product.product.weight * product.quantity;
      })
      let totalWeight = weights.reduce((acc, cur) => {
        return acc + cur
      })
      if(parseFloat(totalWeight) < 140){
        setUpsWeight(totalWeight);
      }
      setShippingWeight(totalWeight);
    }
  }, [cartEntries])

  //Determinal Final Shipping Cost
  useEffect(() => {
    if(needExtraBox){
      setTotalShippingCost((parseFloat(upsRates) + parseFloat(upsExtraBoxRates)).toFixed(2));
    }else setTotalShippingCost((parseFloat(upsRates)));
    if(haveBatteries || freight){
      setTotalShippingCost((parseFloat(victronFreightRates)).toFixed(2));
    }
  }, [upsExtraBoxRates, upsRates, needExtraBox, haveBatteries, freight, victronFreightRates])

  function removeCommas(str) {
    return str.replace(/,/g, '');
  }

  const dataForUpsSimpleRates = {
    "RateRequest": {
      "Request": {
        "RequestOption": "Rate",
        "TransactionReference": {
          "CustomerContext": "CustomerContext"
        }
      },
      "Shipment": {
        "Shipper": {
          "Name": "Simple Indy LLC",
          "ShipperNumber": "H5514V",
          "Address": {
            "AddressLine": [
              "240 S. Chester Ave",
              "ShipperAddressLine",
              "ShipperAddressLine"
            ],
            "City": "INDIANAPOLIS",
            "StateProvinceCode": "IN",
            "PostalCode": "46201",
            "CountryCode": "US"
          }
        },
        "ShipTo": {
          "Name": `${shippingAddress.firstName} ${shippingAddress.lastName}`,
          "Address": {
            "AddressLine": [
              shippingAddress.address,
              shippingAddress.aptSuit,
              "ShipToAddressLine"
            ],
            "City": shippingAddress.city,
            "StateProvinceCode": shippingAddress.state,
            "PostalCode": shippingAddress.zip,
            "CountryCode": "US"
          }
        },
        "ShipFrom": {
          "Name": "Simple Indy LLC",
          "Address": {
            "AddressLine": [
              "240 S. Chester Ave",
              "ShipFromAddressLine",
              "ShipFromAddressLine"
            ],
            "City": "INDIANAPOLIS",
            "StateProvinceCode": "IN",
            "PostalCode": "46201",
            "CountryCode": "US"
          }
        },
        "PaymentDetails": {
          "ShipmentCharge": [{
            "Type": "01",
            "BillShipper": {
              "AccountNumber": "H5514V"
            }
          }]
        },
        "Service": {
          "Code": "003",
          "Description": "Ground"
        },
        "NumOfPieces": "1",
        "Package": [{
          "SimpleRate": {
            "Description": "SimpleRateDescription",
            "Code": shippingBoxSize,
          },
          "PackagingType": {
            "Code": "02",
            "Description": "Packaging"
          },
          "Dimensions": {
            "UnitOfMeasurement": {
              "Code": "IN",
              "Description": "Inches"
            },
            "Length": allDimensions.current,
            "Width": allDimensions.current,
            "Height": allDimensions.current,
          },
          "PackageWeight": {
            "UnitOfMeasurement": {
              "Code": "LBS",
              "Description": "Pounds"
            },
            "Weight": upsWeight.toFixed(0)
          }
        }]
      }
    }
  }
  const dataForUpsStandardRates = {
    "RateRequest": {
      "Request": {
        "RequestOption": "Rate",
        "TransactionReference": {
          "CustomerContext": "CustomerContext"
        }
      },
      "Shipment": {
        "Shipper": {
          "Name": "Simple Indy LLC",
          "ShipperNumber": "H5514V",
          "Address": {
            "AddressLine": [
              "240 S. Chester Ave",
              "ShipperAddressLine",
              "ShipperAddressLine"
            ],
            "City": "INDIANAPOLIS",
            "StateProvinceCode": "IN",
            "PostalCode": "46201",
            "CountryCode": "US"
          }
        },
        "ShipTo": {
          "Name": `${shippingAddress.firstName} ${shippingAddress.lastName}`,
          "Address": {
            "AddressLine": [
              shippingAddress.address,
              shippingAddress.aptSuit,
              "ShipToAddressLine"
            ],
            "City": shippingAddress.city,
            "StateProvinceCode": shippingAddress.state,
            "PostalCode": shippingAddress.zip,
            "CountryCode": "US"
          }
        },
        "ShipFrom": {
          "Name": "Simple Indy LLC",
          "Address": {
            "AddressLine": [
              "240 S. Chester Ave",
              "ShipFromAddressLine",
              "ShipFromAddressLine"
            ],
            "City": "INDIANAPOLIS",
            "StateProvinceCode": "IN",
            "PostalCode": "46201",
            "CountryCode": "US"
          }
        },
        "PaymentDetails": {
          "ShipmentCharge": [{
            "Type": "01",
            "BillShipper": {
              "AccountNumber": "H5514V"
            }
          }]
        },
        "Service": {
          "Code": "003",
          "Description": "Ground"
        },
        "NumOfPieces": "1",
        "Package": [{
          "PackagingType": {
            "Code": "02",
            "Description": "Packaging"
          },
          "Dimensions": {
            "UnitOfMeasurement": {
              "Code": "IN",
              "Description": "Inches"
            },
            "Length": allDimensions.current,
            "Width": allDimensions.current,
            "Height": allDimensions.current,
          },
          "PackageWeight": {
            "UnitOfMeasurement": {
              "Code": "LBS",
              "Description": "Pounds"
            },
            "Weight": upsWeight.toFixed(0)
          }
        }]
      }
    }
  }

  return(
    <div className="checkoutPage">
      <div className="leftContent">
        <h1>Shipping Information</h1>
        {toggleShipping ? 
          <div className="shipping">
            <form onSubmit={handleSubmitShippingAddress} className="shippingForm">
              <h2>Step 1 - Shipping Address</h2>
              <div className="divider2">
                <label htmlFor="firstName">First Name
                  <input 
                    id="firstName" 
                    placeholder="First Name" 
                    value={shippingAddress.firstName} 
                    onChange={(event) => setShippingAddress(prev => ({...prev, firstName: event.target.value}))}
                    required
                    />
                </label>
                <label htmlFor="lastName">Last Name
                  <input 
                    id="lastName" 
                    placeholder="Last Name"
                    value={shippingAddress.lastName}
                    onChange={(event) => setShippingAddress(prev => ({...prev, lastName: event.target.value}))}
                    required
                    />
                </label>              
              </div>
              <label htmlFor="phone">Phone Number
                <input 
                  id="phone" 
                  placeholder="(###)###-####"
                  value={shippingAddress.phone}
                  onChange={(event) => setShippingAddress(prev => ({...prev, phone: event.target.value}))}
                  required
                />
              </label>
              <label htmlFor="address">Address
                <input 
                  id="address" 
                  placeholder="Address"
                  value={shippingAddress.address}
                  onChange={(event) => setShippingAddress(prev => ({...prev, address: event.target.value}))}
                  required
                />
              </label>
              <label htmlFor="aptSuite">Apt. Suite
                <input 
                  id="aptSuite" 
                  placeholder="Apt Suite"
                  value={shippingAddress.aptSuit}
                  onChange={(event) => setShippingAddress(prev => ({...prev, aptSuit: event.target.value}))}
                />
              </label>
              <div className="divider3">
                <label htmlFor="city">City
                  <input 
                    id="city" 
                    placeholder="City"
                    value={shippingAddress.city}
                    onChange={(event) => setShippingAddress(prev => ({...prev, city: event.target.value}))}
                    required
                  />
                </label>
                <label htmlFor="state">State
                  <select 
                    id="state" 
                    placeholder="Select State"
                    value={shippingAddress.state}
                    onChange={(event) => setShippingAddress(prev => ({...prev, state: event.target.value}))}
                    required
                  >
                    <option value="" disabled>Select State</option>
                    {stateAbbreviations.map(state => <option>{state}</option>)}
                  </select>
                </label>
                <label htmlFor="zip">ZIP
                  <input 
                    id="zip" 
                    placeholder="######"
                    value={shippingAddress.zip}
                    onChange={(event) => setShippingAddress(prev => ({...prev, zip: event.target.value}))}
                    required
                  />
                </label>
              </div>
              {isLoadingUPS ? 
                <p>Loading...</p>
                :
                <button type="submit">Use This Address</button>
              }
            </form>
          </div>
          :
          <div>
            <h2 onClick={() => setToggleShipping(true)} >Step 1 - Shipping Address ▼</h2>
          </div>
        }

{/*         {togglePayment ? 
          <div className="payment">
            <h2>Step 2 - Payment Method</h2>
            <div className="creditCardBanner">
              <h2>Credit Card</h2>
              <img src={mastercard} alt="Master Card"/>
              <img src={visa} alt="Visa Card"/>
              <img src={discover} alt="Discover Card"/>
              <img src={americanExpress} alt="American Express Card"/>
            </div>
            <form onSubmit={handleSubmitPayment} className="paymentForm">
              <label htmlFor="cardNumber">Card Number*
                <input 
                  id="cardNumber" 
                  placeholder="#### #### #### ####"
                  value={paymentInformation.cardNumber}
                  onChange={(event) => setPaymentInformation(prev => ({...prev, cardNumber: event.target.value}))}
                  required
                />
              </label>
              <div className="divider2">
                <label >Expiration Date
                  <div className="expiration">
                    <select onChange={(event) => setPaymentInformation(prev => ({...prev, expMonth: event.target.value}))}>
                      <option>01</option>
                      <option>02</option>
                      <option>03</option>
                      <option>04</option>
                      <option>05</option>
                      <option>06</option>
                      <option>07</option>
                      <option>08</option>
                      <option>09</option>
                      <option>10</option>
                      <option>11</option>
                      <option>12</option>
                    </select>
                    <select onChange={(event) => setPaymentInformation(prev=> ({...prev, expYear: event.target.value}))}>
                      {years.map(year => (
                        <option>{year}</option>
                      ))}
                    </select>                   
                  </div>
                </label>
                <label htmlFor="csc">Card Security Code*
                  <input 
                    id="csc" 
                    placeholder="###"
                    value={paymentInformation.CSC}
                    onChange={(event) => setPaymentInformation(prev => ({...prev, CSC: event.target.value}))}
                    required
                  />
                </label>                
              </div>
              <label className="billingAddressCheck">
                <input 
                  type="checkbox"
                  checked={!toggleBillingAddress}
                  onChange={handleBillingEqualShipping}
                />
                Billing Address is the Same as Shipping Address
              </label>
              {toggleBillingAddress ? 
              <>
                <h2>Billing Address</h2>
                <div className="divider2">
                  <label htmlFor="firstNameB">First Name
                    <input 
                      id="firstNameB" 
                      placeholder="First Name" 
                      value={billingAddress.firstName} 
                      onChange={(event) => setBillingAddress(prev => ({...prev, firstName: event.target.value}))}
                      required
                      />
                  </label>
                  <label htmlFor="lastNameB">Last Name
                    <input 
                      id="lastNameB" 
                      placeholder="Last Name"
                      value={billingAddress.lastName}
                      onChange={(event) => setBillingAddress(prev => ({...prev, lastName: event.target.value}))}
                      required
                      />
                  </label>              
                </div>
                <label htmlFor="phoneB">Phone Number
                  <input 
                    id="phoneB" 
                    placeholder="(###)###-####"
                    value={billingAddress.phone}
                    onChange={(event) => setBillingAddress(prev => ({...prev, phone: event.target.value}))}
                    required
                  />
                </label>
                <label htmlFor="addressB">Address
                  <input 
                    id="addressB" 
                    placeholder="Address"
                    value={billingAddress.address}
                    onChange={(event) => setBillingAddress(prev => ({...prev, address: event.target.value}))}
                    required
                  />
                </label>
                <label htmlFor="aptSuiteB">Apt. Suite
                  <input 
                    id="aptSuiteB" 
                    placeholder="Apt Suite"
                    value={billingAddress.aptSuit}
                    onChange={(event) => setBillingAddress(prev => ({...prev, aptSuit: event.target.value}))}
                    required
                  />
                </label>
                <div className="divider3">
                  <label htmlFor="cityB">City
                    <input 
                      id="cityB" 
                      placeholder="City"
                      value={billingAddress.city}
                      onChange={(event) => setBillingAddress(prev => ({...prev, city: event.target.value}))}
                      required
                    />
                  </label>
                  <label htmlFor="stateB">State
                    <select 
                      id="stateB" 
                      placeholder="Select State"
                      value={billingAddress.state}
                      onChange={(event) => setBillingAddress(prev => ({...prev, state: event.target.value}))}
                      required
                    >
                      <option value="" disabled>Select State</option>
                      {stateAbbreviations.map(state => <option>{state}</option>)}
                    </select>
                  </label>
                  <label htmlFor="zipB">ZIP
                    <input 
                      id="zipB" 
                      placeholder="######"
                      value={billingAddress.zip}
                      onChange={(event) => setBillingAddress(prev => ({...prev, zip: event.target.value}))}
                      required
                    />
                  </label>
                </div>              
              </>
              :
              ""}
              <button type="submit">Use This Payment</button>
            </form>
          </div> 
          : 
          <div>
            <h2 onClick={() => setTogglePayment(true)} >Step 2 - Payment Method ▼</h2>
          </div>
        } */}
        {toggleEmail ? 
        <div className="checkOutEmail">
          <form onSubmit={handleSubmitEmail}>
            <h2>Step 2 - Email</h2>
            <label>Email
              <input 
                value={customerEmail} 
                onChange={(event) => setCustomerEmail(event.target.value)}
                placeholder="yourEmail@example.com"
                pattern="[^@]+@[^@]+\.[^@]+"
                title="Please enter a valid email address" 
                required
              />
            </label>
            <button>Use This Email</button>            
          </form>

        </div>
        :
        <div>
          <h2 onClick={() => setToggleEmail(true)} >Step 2 - Email ▼</h2>
        </div>
          }
      </div>
      <div className="rightContent">
        <h2>Cart Summary</h2>
        <div className="cartProductSummary">
          {cartEntries.map((product, index) => (
            <div className="cartProductSummaryLine">
              <span className="left">{product.quantity}x</span>
              <span className="middle">{product.productName}</span>
              <span className="right">${removeCommas(product.product.retail) * product.quantity}</span>
            </div>
          ))}
        </div>
        <h2>Subtotal: ${subTotal}</h2>
        <h2>Shipping: <span>${totalShippingCost}</span></h2>
        {subTotal > 3000 && subTotal < 5000 ? 
          <>
            <h2>Discount: ${(discount).toFixed(2)}</h2> 
          </>
        :
        ""
        }
        {subTotal > 5000 ? 
          <>
            <h2>Discount: ${(discount).toFixed(2)}</h2> 
          </>
          :
          ""
        }
        <h2>Total: ${total.toFixed(2)}</h2>
        {checkForms.shipping /* && checkForms.payment */ && checkForms.email ?
        <>
          {isLoading ? 
            <p>Loading...</p> 
            : 
            <button onClick={handleCheckOut}>Receive Invoice</button>}
          {freight ? 
          <>
            <p>Once Payment is Acknowledged, a Confirmation Email with Tracking Details will be Sent.</p>
            <p>Due to oversized items, shipping will be done through feight shipping.</p>
            <p>Please allow 5 - 10 Business Days for the Items to be shipped</p>
          </> 
          :
          <>
            <p>Once Payment is Acknowledged, a Confirmation Email with Tracking Details will be Sent.</p>
            <p>Please allow 3 - 5 Business Days for the Items to be shipped</p>
          </>
          }
        </>
        
        :
        ""
        }
      </div>
    </div>
  )
}

export default Checkout;