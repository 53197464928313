import React from "react";

import netBilling from '../education-images/BatteriesGT_NetBilling.jpg';
import independent from '../education-images/BatteriesGT_IndependentFromGrid.jpg';
import powerOutage from '../education-images/BatteriesGT_PowerOutage.jpg';

const BenefitsOfBatteries = () => {

    return(
        <div className="BGT banner3BGT">
            <h1>Benefits of Having Batteries</h1>
            <div className="section">
                <section>
                    <h2>Save More Than With Net-Metering</h2>
                    <p>
                      Instead of selling excess electricity for 
                      a lower price, storing it in batteries 
                      allows for greater savings.
                    </p>
                </section>
                <img src={netBilling} alt="pic"/>
            </div>
            <div className="section">
                <section>
                    <h2>Independence From The Grid</h2>
                    <p>
                      With batteries, it's possible to live without 
                      relying on the utility company.
                    </p>
                </section>
                <img src={independent} alt="pic"/>
            </div>
            <div className="section">
              <section>
                <h2>Emergency Backup</h2>
                <p>
                  A specified amount of energy can be saved to 
                  provide the home with backup power in the case 
                  of severe weather.
                </p>
              </section>
              <img src={powerOutage} alt="pic"/>
            </div>
        </div>
    )
}

export default BenefitsOfBatteries;