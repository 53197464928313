import React from "react";

import BGT5 from '../education-images/BatteriesGT5.webp';

const HowManyBatteries = () => {

    return(
        <div className="BGT banner5BGT">
            <h1>How Many Batteries Should I Get?</h1>
            <div className="section">
                <section>
                    <h2>
                        Before we can answer this question for you, we will need 
                        to know:
                    </h2>
                    <h3>
                      1. Desired hours worth of BackUp?
                    </h3>
                    <h3>
                      2. Nighttime power consumption
                      (e.g., continuous use of appliances like air conditioning)?
                    </h3>
                    <h2>
                      Knowing the answers helps determine the number of batteries required.
                    </h2>
                </section>
                <img src={BGT5} alt="pic"/>
            </div>
        </div>
    )
}

export default HowManyBatteries;