import React from 'react';
import { Routes, Route } from 'react-router-dom';
import './styles/quote.css';

import ChooseYourQuote from './children-quote/ChooseYourQuote.js';
import Confirmation from './children-quote/Confirmation.js'
import QuoteForm from './children-quote/QuoteForm.js';

const Quote = () => {
    return(
        <div className="formContent">
            <Routes>
                <Route path="/" element={<ChooseYourQuote />} />
                <Route path="residential" element={<QuoteForm type="residential" />} />
                <Route path="commercial" element={<QuoteForm type="commercial" />} />
                <Route path="offGrid" element={<QuoteForm type="offGrid" />} />
                <Route path="rv" element={<QuoteForm type="rv" />} />
                <Route path="confirmation" element={<Confirmation />} />
            </Routes>
        </div>
    )
}

export default Quote;