

function CustomRadio({ isChecked, onRadioChange, dataValue, name }) {
    const id = `radio-${name}-${dataValue}`;

    return (
        <>
            <input 
                type="radio"
                id={id}
                name={name}
                className="hidden-radio"
                checked={isChecked}
                onChange={onRadioChange}
                value={dataValue}
            />
            <label htmlFor={id} className="custom-checkbox-label"></label>        
        </>

    );
}


export default CustomRadio;