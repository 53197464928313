import React from "react";
import { Link } from "react-router-dom";
import GetQuote from '../education-images/GetQuote.jpg';

const Next = () => {
    return(
        <div className="banner7RV">
        <div>
          <h2>What is Next?</h2>
          <div className="options">
            <Link to="../../Quote">
              <div className="option">
                <img src={GetQuote} alt="pic" />
                <h3>Get a Quote?</h3>
              </div>                 
            </Link>
           
          </div>
        </div>
      </div>
    )
}

export default Next;