import React from "react";

import RoofMountPicture from '../education-images/Roof Mount.jpg';
import GroundMountPicture from '../education-images/Ground Mount.jpg';

const Mounts = () => {
    return(
        <div className="banner2GT">
            <h1>Roof Mount or Ground Mount?</h1>
            <div className="doubleContainer">
                <div className="leftContainer">
                    <h2><u>Roof Mount</u></h2>
                    <img src={RoofMountPicture} alt="pic"/>
                    <h3><u>Pros</u></h3>
                    <ul>
                        <li>
                            <strong>Cost-Effectiveness</strong>
                        </li>
                        <li>
                            <strong>Space Efficiency</strong>
                        </li>
                        <li>
                            <strong>Elevated Positioning</strong>
                        </li>
                        <li>
                            <strong>Snow Slides Right Off</strong>
                        </li>
                    </ul>

                    <h3><u>Cons</u></h3>
                    <ul>
                      <li>
                        <strong>Roof Repairs</strong>
                        <p>
                          - Repairing roofs will require the removal and 
                          re-installation of the panels
                        </p>
                        <p>
                          - Recommended to repair/replace roof before first 
                          installation.
                        </p>
                      </li>
                      <li>
                        <strong>Maintenance and Cleaning</strong>
                        <p>
                          - While not often required, cleaning can be difficult 
                          if the panels are installed on a roof with a high elevation
                        </p>
                      </li>
                      <li>
                        <strong>Snow Hazard</strong>
                        <p>
                          - Because snow slides off the panels, it's imperative to be 
                          cautious around the panels after snowfall
                        </p>
                      </li>
                    </ul>
                </div>
                <div className="rightContainer">
                    <h2><u>Ground Mount</u></h2>
                    <img src={GroundMountPicture} alt="pic"/>
                    <h3><u>Pros</u></h3>
                    <ul>
                        <li>
                        <strong>Location Flexibility</strong>
                        </li>
                        <li>
                        <strong>Accessibility</strong>
                        </li>
                        <li>
                        <strong>Expandability</strong>
                        <p>
                            - Each section of ground mounts typically holds 20 panels.
                        </p>
                        </li>
                        <li>
                        <strong>Optional: Panel Tilt Optimization</strong>
                        <p>
                            - Solar Panels can lose up to 25% productivity simply 
                            due to suboptimal tilt angle. 
                        </p>
                        </li>
                    </ul>
                    <h3><u>Cons</u></h3>
                    <ul>
                        <li>
                          <strong>Higher Costs</strong>
                        </li>
                        <li>
                          <strong>Damage to Terrain</strong>
                        </li>
                        <li>
                          <strong>More Stringent Local Regulations</strong>
                        </li>
                    </ul>
                </div>
            </div>

        </div>
    )
}

export default Mounts;