import React from "react";

//import GridTied4 from '../education-images/GridTied4.jpg';
import GridTied5 from '../education-images/GridTied5.png';
import GridTied6 from '../education-images/GridTied6.png';


const Products = () => {
    return(
        <div>
            <div className="banner4GT">
                <h1>Products We Use</h1>  
{/*                 <div className="product">
                    <img alt="pic" src={GridTied4}/>
                    <section className="descriptionGT">
                    <h2>SolarEdge Home Wave Inverters</h2>
                    <p>
                        <strong>Product:</strong> Inverter<br/>
                        <strong>Description:</strong> The SolarEdge single phase inverter 
                        with Home Wave technology breaks the mold of traditional solar inverters. 
                        Winner of the prestigious 2016 Intersolar Award and the renowned 2018 Edison 
                        Award, the single phase inverter is specifically designed to work with SolarEdge 
                        power optimizers. It comes with a built-in DC safety switch, integrated rapid 
                        shutdown, and features a standard 12-year warranty extendable to 20 or 25 years.
                    </p>
                    </section>
                </div> */}
                <div className="product">
                    <img alt="pic" src={GridTied5}/>
                    <section className="descriptionGT">
                        <h2>SOL-ARK 15K-2P</h2>
                        <p>
                            <strong>Product:</strong> Inverter<br/>
                            <strong>Description:</strong> Converts the power from solar panels
                            to usable power for your home.<br/>
                        </p>
                    </section>
                </div>
                <div className="product">
                    <img alt="pic" src={GridTied6}/>
                    <section className="descriptionGT">
                        <h2>
                            Victron Energy Quattro 5kW to 10kW
                        </h2>
                        <h2>
                            (Coming Soon)
                        </h2>
                        <p>
                            <strong>Product:</strong> Inverter / Charger<br/>
                            <strong>Description:</strong> The Quattro is a combined 
                            inverter and charger. Additionally it can accept two AC 
                            inputs and automatically connect to the active source. 
                            When Coupled with a GX device, you can monitor and control 
                            your Quattro and system or systems' locally (LAN) or remotely 
                            via the internet.
                        </p>
                    </section>
                </div>
        </div>
      </div>
    )
}

export default Products;