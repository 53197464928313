import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import aesLogo from "./images-home/Logos/AES_Logo.png";
import briggsLogo from "./images-home/Logos/briggs-stratton-Logo.png";
import enphaseLogo from "./images-home/Logos/enphase-Logo.png";
import generacLogo from "./images-home/Logos/Generac_Power_Systems_logo.png";
import homegridLogo from "./images-home/Logos/homegrid-logo.png";
import liniotechLogo from "./images-home/Logos/liniotech-Logo.png";
import qcellsLogo from "./images-home/Logos/q-cells-co-solar-power-solar-panels-energy-logo.png";
import richSolarLogo from "./images-home/Logos/RichSolarLogoSmall.png";
import silfabLogo from "./images-home/Logos/Silfab-Logo.png";
import solArkLogo from "./images-home/Logos/sol-ark-Logo.png";
import teslaLogo from "./images-home/Logos/Tesla_logo.png";
import victronLogo from "./images-home/Logos/victron-logo.png";

const Logos = () => {
  const [prefersReducedMotion, setPrefersReducedMotion] = useState(false);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: windowSize.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [windowSize.innerWidth])

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');
    
    const updatePreference = (e) => {
      setPrefersReducedMotion(e.matches);
    }

    mediaQuery.addEventListener('change', updatePreference);

    setPrefersReducedMotion(mediaQuery.matches);

    return () => {
      mediaQuery.removeEventListener('change', updatePreference)
    }
    
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: prefersReducedMotion ? 0 : 5000,
    slidesToShow: windowSize.width <= 550 ? 3 : 4,
    slidesToScroll: 1,
    autoplay: !prefersReducedMotion,
    autoplaySpeed: 500,
    cssEase: prefersReducedMotion ? "none" : "linear",
    pauseOnHover: false,
    arrows:false,
    centerMode:true,
    centerPadding:"50px",
  };

  const settingsRTL = {
    dots: false,
    infinite: true,
    speed: prefersReducedMotion ? 0 : 5000,
    slidesToShow: windowSize.width <= 550 ? 3 : 4,
    slidesToScroll: 1,
    autoplay: !prefersReducedMotion,
    autoplaySpeed: 500,
    cssEase: prefersReducedMotion ? "none" : "linear",
    pauseOnHover: false,
    arrows:false,
    centerMode:true,
    centerPadding:"50px",
    rtl:true,
  };
  
  const logoSourceTop = [
    <img src={aesLogo} alt="AES Logo"/>,
    <img src={briggsLogo} alt="Briggs Logo"/>,
    <img src={enphaseLogo} alt="Enphase Logo"/>,
    <img src={generacLogo} alt="Generac Logo"/>,
  ];

  const logoSourceMiddle = [
    <img src={homegridLogo} alt="Homegrid Logo"/>,
    <img src={liniotechLogo} alt="Liniotech Logo"/>,
    <img src={qcellsLogo} alt="Q Cells Logo"/>,
    <img src={richSolarLogo} alt="Rich Solar Logo"/>,
  ]

  const logoSourceBottom = [
    <img src={silfabLogo} alt="Silfab Logo"/>,
    <img src={solArkLogo} alt="Sol-Ark Logo"/>,
    <img src={teslaLogo} alt="Tesla Logo"/>,
    <img src={victronLogo} alt="Victron Logo"/>,
  ];

  return(
    <div className="logoMainContainer">
      <h1 className="logoH1">Associated Dealers</h1>
      <Slider {...settings} className="logoSubContainer">
        {logoSourceTop.map(logo => (
          <div className="logoImageContainer" key={logo}>
            {logo}
          </div>
        ))}        
      </Slider>
      <Slider {...settingsRTL} className="logoSubContainer">
        {logoSourceMiddle.map(logo => (
          <div className="logoImageContainer" key={logo}>
            {logo}
          </div>
        ))}        
      </Slider>
      <Slider {...settings} className="logoSubContainer">
        {logoSourceBottom.map(logo => (
          <div className="logoImageContainer" key={logo}>
            {logo}
          </div>
        ))}        
      </Slider>
    </div>
  )
}

export default Logos;