import React from "react";

const SolarInfluence = () => {
  return (
    <div>
      <div className="banner3SP">
        <h1>How Can Solar Panels Change Your Life?</h1>
        <ul>
          <li>
            <strong>Lower Utility Bills</strong>
            <p>
                A typical household in America pays 
                between $75 to $200 per month for electricity. 
                Based on how many solar panels can be installed, 
                a large portion of this cost can be offset.
            </p>
          </li>
          <li>
              <strong>Tax Credit</strong>
              <p>
                The Federal investment tax credit allows you to receive
                up to a 30% tax credit against your tax liability. This 
                incentive can be applied 5 years retroactively or it can 
                be spread out for up to 5 years in the future. 
              </p>
          </li>
          <li>
              <strong>Sec. 179 Depreciation</strong>
              <p>
                If you own and operate a business out of your home, you 
                can also depreciate the value of your solar system in the first 
                year. This incentive can also be applied 5 years retroactively or 
                it can be spread out for up to 5 years in the future.
              </p>
          </li>
          <li>
              <strong>Increase House Value</strong>
              <p>
                Having doubts because you may not stay in that house for 
                long? Don't worry, because installing solar can increase 
                the value of the house and appeal.
              </p>
          </li>
        </ul>
      </div>            
  </div>
  )
}

export default SolarInfluence;