import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function EmailForm({onSubmit, answers, address, form}) {
  const otherAnswers = useSelector((state) => state.conditionalQuestions.answers)
  const navigate = useNavigate();
  const formInfo = {...answers, ...address, ...otherAnswers};
  
  const residentialParametersForEmail = 
    { "form" : form,
      "answers" : [
      {"question": "First Name :", "answer": address['*First Name']},
      {"question": "Last Name :", "answer": address['*Last Name']},
      {"question": "Installation Address :", "answer": address['Address']},
      {"question": "City :", "answer": address['City']},
      {"question": "State :", "answer": address['State']},
      {"question": "Zip :", "answer": address['Zip']},
      {"question": "Email :", "answer": address['*Email']},
      {"question": "Phone :", "answer": address['*Phone']},
      {"question": "Average Monthly Electricity Bill :", "answer": answers.electricitybill},
      {"question": "Utility Company :", "answer": answers.utility},
      {"question": "Customer's Desired Offset :", "answer": answers.offset},
      {"question": "Want Roof Mount :", "answer": answers.roof_mount},
      {"question": "Want Ground Mount :", "answer": answers.ground_mount},
      {"question": "Type Of Roof :", "answer": answers.type_of_roof},
      {"question": "Want Batteries :", "answer": answers.battery},
      {"question": "Desired Backup Hours :", "answer": answers.q5a},
      {"question": "Preferred Form Of Communication :", "answer": answers.preferredcommunication},
      {"question": "Other Comments :", "answer": answers.message}
    ]};

    const commercialParametersForEmail = 
    { "form" : form,
      "answers" : [
      {"question": "First Name :", "answer": address['*First Name']},
      {"question": "Last Name :", "answer": address['*Last Name']},
      {"question": "Installation Address :", "answer": address['Address']},
      {"question": "City :", "answer": address['City']},
      {"question": "State :", "answer": address['State']},
      {"question": "Zip :", "answer": address['Zip']},
      {"question": "Email :", "answer": address['*Email']},
      {"question": "Phone :", "answer": address['*Phone']},
      {"question": "Average Monthly Electricity Bill :", "answer": answers.electricitybill},
      {"question": "Utility Company :", "answer": answers.utility},
      {"question": "Customer's Desired Offset :", "answer": answers.offset},
      {"question": "Want Roof Mount :", "answer": answers.roof_mount},
      {"question": "Want Ground Mount :", "answer": answers.ground_mount},
      {"question": "Type Of Roof :", "answer": answers.type_of_roof},
      {"question": "Preferred Form Of Communication :", "answer": answers.preferredcommunication},
      {"question": "Other Comments :", "answer": answers.message}
    ]};

    const offGridParametersForEmail = 
    { "form" : form,
      "answers" : [
      {"question": "First Name :", "answer": address['*First Name']},
      {"question": "Last Name :", "answer": address['*Last Name']},
      {"question": "Installation Address :", "answer": address['Address']},
      {"question": "City :", "answer": address['City']},
      {"question": "State :", "answer": address['State']},
      {"question": "Zip :", "answer": address['Zip']},
      {"question": "Email :", "answer": address['*Email']},
      {"question": "Phone :", "answer": address['*Phone']},
      {"question": "Do Customers know their Power Usage :", "answer": answers.powerUsage},
      {"question": "Daily Power Usage :", "answer": answers.dailyPowerUsageAmount},
      {"question": "Monthly Power Usage :", "answer": answers.monthlyPowerUsageAmount},
      {"question": "Want Roof Mount :", "answer": answers.roof_mount},
      {"question": "Want Ground Mount :", "answer": answers.ground_mount},
      {"question": "Type Of Roof :", "answer": answers.type_of_roof},
      {"question": "Interested in HomeGrid? :", "answer": answers.homeGrid},
      {"question": "Interested in LinioTech? :", "answer": answers.linioTech},
      {"question": "Interested in ReLiOn? :", "answer": answers.reLiOn},
      {"question": "Desired Backup Hours :", "answer": answers.battery_hours},
      {"question": "How often the Customer is okay with Running their Generator :", "answer": answers.sustainability},
      {"question": "Preferred Form Of Communication :", "answer": answers.preferredcommunication},
      {"question": "Other Comments :", "answer": answers.message}
    ]};

    const rvParametersForEmail = 
    { "form" : form,
      "answers" : [
      {"question": "First Name :", "answer": address['*First Name']},
      {"question": "Last Name :", "answer": address['*Last Name']},
      {"question": "Installation Address :", "answer": address['Address']},
      {"question": "City :", "answer": address['City']},
      {"question": "State :", "answer": address['State']},
      {"question": "Zip :", "answer": address['Zip']},
      {"question": "Email :", "answer": address['*Email']},
      {"question": "Phone :", "answer": address['*Phone']},
      {"question": "Coach Model :", "answer": answers.coachModel},
      {"question": "Wants to install Solar :", "answer": answers.solar},
      {"question": "Wants to install Lithium Batteries :", "answer": answers.lithium_batteries},
      {"question": "Wants to install Victron Electricl Systems :", "answer": answers.system_upgrade},
      {"question": "Current Lead Acid Battery Ah :", "answer": answers.currentBatteries},
      {"question": "Desired Lithium Battery Ah :", "answer": answers.lithiumBatteries},
      {"question": "Desired Number of Solar Panels :", "answer": answers.solarPanels},
      {"question": "Desired Legs to Power:", "answer": answers.breakerQuestion},
      {"question": "Preferred Month of Installation :", "answer": answers.preferredMonthOfInstallation},
      {"question": "Preferred Form Of Communication :", "answer": answers.preferredcommunication},
      {"question": "Other Comments :", "answer": answers.message}
    ]};

  let sendEmailParamters;
  let formId;
  switch (form) {
    case "Residential":
      sendEmailParamters = residentialParametersForEmail;
      formId = process.env.REACT_APP_RESIDENTIAL_FORMID;
      break;
    case "Commercial":
      sendEmailParamters = commercialParametersForEmail;
      formId = process.env.REACT_APP_COMMERCIAL_FORMID;
      break;
    case "Off-Grid":
      sendEmailParamters = offGridParametersForEmail;
      formId = process.env.REACT_APP_OFFGRID_FORMID;
      break;
    case "RV":
      sendEmailParamters = rvParametersForEmail;
      formId = process.env.REACT_APP_RV_FORMID;
      break;
    default:
      sendEmailParamters = null;
  }

    
    const formData = {
        fields: Object.keys(formInfo).map((key) => ({
            name: key,
            value: formInfo[key],
          })),
      // Use your HubSpot context information here (if necessary)
      context: {
        pageUri: 'http://www.example.com/page',
        pageName: 'Example page',
      },
    };

    const handleSubmit =  async (event) => {

      if(!address['*First Name'] || !address['*Last Name'] || !address['*Email'] || !address['*Phone']) {
        return
      }

      try {
        await fetch(
          `https://api.hsforms.com/submissions/v3/integration/submit/${process.env.REACT_APP_PORTALID}/${formId}`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
          }
        );

        await fetch('/.netlify/functions/send-email', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            to: 'sales@simpleindy.com',
            from: 'jason@simpleindy.com',
            subject: `${form} Lead`,
            text: 'This is a fallback text content!',
            html: '<p>This is a fallback HTML content!</p>',
            templateId: 'd-112c796f8ca94790bd5d2c9e10fa477d',
            dynamicData: sendEmailParamters,
          }),
        })
        .then(response => response.json())
        .then(data => console.log(data))
        .catch(error => console.error(error));
        
        /*if (!response.ok) {
          throw new Error('Network response was not ok');
        } */
        console.log('Form submitted successfully');
        navigate('../confirmation')
      } catch (err) {
        console.error('Error submitting form: ', err);
      }
    }

    return (
      <button 
        type="submit" 
        onClick={() => handleSubmit()} 
        className="quoteRequestButton">Request Quote
      </button>
  );
}

export default EmailForm;
