// src/features/token/tokenSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Define an asynchronous thunk for fetching the token
export const fetchUpsToken = createAsyncThunk(
  'token/fetchToken',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetch('/.netlify/functions/getUpsToken');
      if (!response.ok) throw new Error('Network response was not ok');
      const data = await response.json();
      return data.access_token;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Create a slice for the token with reducers to handle the various states of the fetch operation
const upsTokenSlice = createSlice({
  name: 'upsToken',
  initialState: {
    token: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUpsToken.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUpsToken.fulfilled, (state, action) => {
        state.loading = false;
        state.token = action.payload;
        state.error = null;
      })
      .addCase(fetchUpsToken.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default upsTokenSlice.reducer;
