import React from "react";

import BGT2 from '../education-images/BatteriesGT2.png';
import BGT3 from '../education-images/BatteriesGT3.webp';
//import BGT4 from '../education-images/BatteriesGT4.png';

const Products = () => {

    return(
        <div className="BGT banner4BGT">
            <h1>Products We Work With</h1>
            <div className="product">
                <img alt="pic" src={BGT2}/>
                <section className="descriptionGT">
                    <h2>SOL-ARK 15K-2P</h2>
                    <p>
                        <strong>Product: </strong>Inverter, MPPT, Charger<br/>
                        <strong>Description: </strong>Highest Rated Inverter on 
                        Energy Sage. Combines 3 equipment into 1, making Sol-Ark 
                        the bread and butter for almost everything in the system.<br/>
                    </p>
                </section>
            </div>
            <div className="product">
                <img alt="pic" src={BGT3}/>
                <section className="descriptionGT">
                    <h2>HomeGrid Battery (Stack'd series)</h2>
                    <p>
                        <strong>Product: </strong>Stackable Battery<br/>
                        <strong>Description: </strong>Designed to be easily 
                        expandable. The HomeGrid Battery (Stack'd series) 
                        brings around 9.6kWh, and stacks to a total of 
                        38.4kWh. (Typical American household uses around 
                        30kWh a day)
                    </p>
                    <p>
                        <strong>Certification: </strong>By SGS 
                        (Well renowned): NEC 690.4B & NEC 705.4/6.
                    </p>
                    <p>
                        <strong>Certification: </strong>Grid Sell Back: 
                        — UL1741-2010/2018, IEEE1547a 2003/2014 
                    </p>
                </section>
            </div>
{/*             <div className="product">
                <img alt="pic" src={BGT4}/>
                <section className="descriptionGT">
                    <h2> SolarEdge - Battery 9.7kWh</h2>
                    <p>
                        <strong>Product: </strong>Battery<br/>
                        <strong>Description: </strong>A well designed battery 
                        that interacts with other SolarEdge Equipment. A perfect 
                        choice if you already have SolarEdge Inverters.
                    </p>
                    <p>
                        <strong>Certification: </strong>UL9540A
                    </p>
                </section>
            </div> */}
        </div>
    )
}

export default Products;