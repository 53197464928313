import React from "react";

const UtilityWithSolar = () => {
  return(
    <div className="banner5GT">
      <h1>How Your Utility & Solar Work Together</h1>
      <p>
        After a bi-directional meter is installed, consumption and 
        production data are monitored.
      </p>
      <p>
        Customers are credited at the utility's wholesale rate 
        for excess energy fed back into the grid.
      </p>
    </div>
  )
}

export default UtilityWithSolar;