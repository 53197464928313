import React, { useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import backgroundH1 from './images-home/CarouselContent1.jpg';
import backgroundH2 from './images-home/CarouselContent2.jpg';
import backgroundH3 from './images-home/CarouselContent3.jpg';
import backgroundA1 from '../children-about/images-about/CarouselContent1.jpg';
import backgroundA2 from '../children-about/images-about/CarouselContent2.jpg';
import backgroundA3 from '../children-about/images-about/CarouselContent3.jpg';
import backgroundAS1 from '../children-about/images-about/SmallCarouselContent1.jpg';
import backgroundAS2 from '../children-about/images-about/SmallCarouselContent2.jpg';
import backgroundAS3 from '../children-about/images-about/SmallCarouselContent3.JPG';

const CarrouselContent = (props) => {

    const [dimensions, setDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    useEffect(() => {
    const handleResize = () => {
        setDimensions({
        width: window.innerWidth,
        height: window.innerHeight
        });
    };

    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => window.removeEventListener('resize', handleResize);
    }, []);

    const navigate = useNavigate();
    
    const handleOnClick = (e) => {
        navigate(`./${e.target.value}`);
        window.scrollTo(0,0);
    }

    let content = props.value;

    const bgStyle = {
        "backgroundPosition":"center",
        "backgroundRepeat":"no-repeat",
        "backgroundSize":"cover",
        "resize":"both",
        "height":"400px",
        "width":"100%",
    }

    return(
        <div>
            {content === "H1" ? (
                <div style={{
                    "backgroundImage":`url(${backgroundH1})`,
                    ...bgStyle
                }} className="Carrousel">
                    <h1>Get The Best Of The Best For Your RV</h1>
                    <h1>From our Victron Energy Equipment</h1>
                    <button value="Quote" onClick={handleOnClick}>Get A Quote</button>                    
                </div>    
            ) : ""}
            {content === "H2" ? (
                <div style={{
                    "backgroundImage":`url(${backgroundH2})`,
                    ...bgStyle
                    }} className="Carrousel">
                    <h1>Be Confident With Solar</h1>
                    <h1>Learn More About Solar Here</h1>
                    <button value="Contact" onClick={handleOnClick}>Contact Us</button>
                </div>
            ) : ""}
            {content === "H3" ? (
                <div style={{
                    "backgroundImage":`url(${backgroundH3})`,
                    ...bgStyle
                    }} className="Carrousel">
                    <h1>Check Out Some Of Our Recent Jobs</h1>
                    <h1>We Take Pride In Our Craftsmanship</h1>  
                    <button value="About_Us" onClick={handleOnClick}>About Us</button>
                </div>
            ): ""}
            {content === "A1" && (
                <div style={{
                    "backgroundImage":`url(${dimensions.width < 600 ? backgroundAS1: backgroundA1})`,
                    ...bgStyle
                    }} className="Carrousel">
                    <h1>Kendall Ludwig - Victron Ambassador</h1>
                </div>
            )}
            {content === "A2" && (
                <div style={{
                    "backgroundImage":`url(${dimensions.width < 600 ? backgroundAS2: backgroundA2})`,
                    ...bgStyle
                    }} className="Carrousel">
                    <h1>Trade Shows - Stop By And Say Hi</h1> 
                </div>
            )}
            {content === "A3" && (
                <div style={{
                    "backgroundImage":`url(${dimensions.width < 600 ? backgroundAS3: backgroundA3})`,
                    ...bgStyle
                    }} className="Carrousel">
                    <h1>We Work Hard To Provide</h1> 
                    <h1>The Very Best</h1>
                </div>
            )}
        </div>        
    )
}

export default CarrouselContent;