import React from "react";

import Residential from './images-home/WhatWeOffer1.JPG';
import Commercial from './images-home/WhatWeOffer2.JPG';
import RV from './images-home/WhatWeOffer3.jpg';
import OffGrid from './images-home/WhatWeOffer4.jpg';
import TeslaCharger from './images-home/WhatWeOffer5.jpeg';

const WhatWeOffer = () => {

    const solutions = ["Residential", "Commercial", "RV", "Off-Grid", "Tesla Chargers"];
    const pictures = [Residential, Commercial, RV, OffGrid, TeslaCharger];
    return (
        <div className="offer-banner">
            <h1>Residential | Commerical | RV | Off-Grid | Tesla Chargers</h1>
            <h2>We Offer a Wide Variety of Solutions</h2>
            <div className="infos">
                {solutions.map((solution, index) => (
                    <div className="info-container" key={solution}> 
                        <img alt={`${solution}-pic`} src={pictures[index]}/>
                        <span className="info">{solution}</span>
                    </div>
                ))}                
            </div>

        </div>
    )
}

export default WhatWeOffer;