import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/home.css'
import background from '../children-home/images-home/quote.jpg'

const GetAQuote = () => {

    const navigate = useNavigate();

    const handleGoToQuote = () => {
        navigate('./Quote');
        window.scrollTo(0,0);
    }

    const style = {
        "display":"flex",
        "justifyContent":"center",
    }

    const bgStyle = {
        "backgroundPosition":"center",
        "backgroundRepeat":"no-repeat",
        "backgroundSize":"cover",
        "resize":"both",
        "backgroundImage":`url(${background})`
    }

    return(
    <div className="banner getAQuote" style={{...style, ...bgStyle}}>
      <h1>Want To Start Saving Today?</h1>
      <button onClick={handleGoToQuote}>Get a Quote</button>
    </div>
    )
}

export default GetAQuote;