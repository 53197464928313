// slice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  answers: {
    
  },
};

export const conditionalQuestionSlice = createSlice({
  name: 'conditionalQuestions',
  initialState,
  reducers: {
    setAnswers: (state, action) => {
      const { name, value } = action.payload;
      state.answers[name] = value;
    },
  },
});

// Export the action creator
export const { setAnswers } = conditionalQuestionSlice.actions;

// Export the reducer
export default conditionalQuestionSlice.reducer;
