import React from "react";

import RV3 from '../education-images/RV3.png';
import RV4 from '../education-images/RV4.png';
import RV5 from '../education-images/RV5.png';

const Q2GeneratorRunTime = () => {
    return(
      <div className="banner4RV">
        <h2>Generator Usage Optimization</h2>
        <div style={{fontSize:"xx-large"}} className="banner4RV">
          <p>Deciding on generator usage is critical for efficiency and convenience. The goal for many is to minimize or eliminate the need to run a generator.</p>
          <img src={RV3} alt="Generator usage chart"/>
          <p>To accurately assess your power consumption and tailor your energy system accordingly, consider these methods:</p>
          <ol>
            <li>
              <p>
                Use a Victron Energy SmartShunt coupled with an optional Cerbo device. These tools provide precise monitoring of power usage. The Cerbo, while optional, grants access to the VRM online portal, enabling detailed analysis of your system's historical data, which assists in determining the necessary capacity to fulfill your usage requirements.
              </p>
              <div className="imagesRV">
                <img src={RV4} alt="Victron Energy SmartShunt"/>
                <img src={RV5} alt="Victron Energy Cerbo device"/>
              </div>
            </li>
            <li>
              <p>
                Monitor how often you need to charge your lead-acid batteries from 50% to full capacity. This frequency, along with the size of your battery bank, can provide an estimate of your daily power usage. Add a margin to this estimate to ensure sufficient capacity.
              </p>
            </li>
          </ol>
          <p>If you find your current battery capacity insufficient (or if you answered "NO" in the previous question), adjust the ampere-hours of your battery bank accordingly to balance your generator usage preferences.</p>          
        </div>

      </div>
    )
}

export default Q2GeneratorRunTime;