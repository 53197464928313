// store.js
import { configureStore } from '@reduxjs/toolkit';
import conditionalQuestionsReducer from './conditionalQuestionSlice';
import firebaseDBReducer from './firebaseSlice';
import cartReducer from './cartSlice'
import upsTokenReducer from './ups_token';

export const store = configureStore({
  reducer: {
    conditionalQuestions: conditionalQuestionsReducer,
    firebaseDB: firebaseDBReducer,
    cart:cartReducer,
    upsToken:upsTokenReducer,
  },
});

