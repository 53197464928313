import React from "react";

import OffGrid4 from '../education-images/OffGrid4.webp';
import OffGrid5 from '../education-images/OffGrid5.png';
import OffGrid6 from '../education-images/OffGrid6.png';

const Products = () => {
    return(
        <div className="banner5OG">
            <h1>Products We Use</h1>
            <div>
                <div className="product">
                    <img alt="pic" src={OffGrid4}/>
                    <section className="descriptionGT">
                    <h2>HomeGrid Battery (Stack'd series)</h2>
                    <p>
                        <strong>Product: </strong>Stackable Battery<br/>
                        <strong>Description: </strong>Designed to be easily 
                        expandable. The HomeGrid Battery (Stack'd series) 
                        brings around 9.6kWh, and stacks to a total of 
                        38.4kWh. (Typical American household uses around 
                        30kWh a day)
                    </p>
                    <p>
                        <strong>Certification: </strong>By SGS 
                        (Well renowned): NEC 690.4B & NEC 705.4/6.
                    </p>
                    <p>
                        <strong>Certification: </strong>Grid Sell Back: 
                        — UL1741-2010/2018, IEEE1547a 2003/2014 
                    </p>
                </section>
                </div>
                <div className="product">
                    <img alt="pic" src={OffGrid5}/>
                    <section className="descriptionGT">
                    <h2>SOL-ARK 15K-2P</h2>
                    <p>
                        <strong>Product: </strong>Inverter, MPPT, Charger<br/>
                        <strong>Description: </strong>Highest Rated Inverter on 
                        Energy Sage. Combines 3 equipment into 1, making Sol-Ark 
                        the bread and butter for almost everything in the system.<br/>
                    </p>
                </section>
                </div>
                <div className="product">
                    <img alt="pic" src={OffGrid6}/>
                    <section className="descriptionGT">
                        <h2>
                            Victron Energy Quattro 5kW to 15kW
                        </h2>
                        <p>
                            <strong>Product:</strong> Inverter / Charger<br/>
                            <strong>Description:</strong> The Quattro is a combined 
                            inverter and charger. Additionally it can accept two AC 
                            inputs and automatically connect to the active source. 
                            When Coupled with a GX device, you can monitor and control 
                            your Quattro and system or systems' locally (LAN) or remotely 
                            via the internet.
                        </p>
                    </section>
                </div>

            </div>
          </div>
    )
}

export default Products;