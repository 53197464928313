
import groundMountPicture from './quote-images/QC-1.jpg';
import roofMountPicture from './quote-images/QC-2.jpg';

const utilityCostOptions = [];
utilityCostOptions.push("---Select---");
for (let i = 300; i <= 5000; i += 100) {
    utilityCostOptions.push(i);
}
utilityCostOptions.push("More than 5000")

const batteryBackUpHours = [];
for (let i = 0; i <= 48; i += 1) {
  batteryBackUpHours.push(`${i} Hours`);
}

const utilityCompanies = [
  "---Select Utility---",
  "AES Indiana",
  "CenterPoint Energy",
  "Duke Energy Indiana",
  "Hoosier Energy",
  "Indiana Electric Cooperatives",
  "Indiana Michigan Power (I&M)",
  "Indiana Municipal Power Agency (IMPA)",
  "Northern Indiana Public Service Company (NIPSCO)",
  "Wabash Valley Power Alliance",
  "N/A"
]

const roofTypes = ["Shingles", "Metal", "Flat Rubber"];

const commercialQuestionData = [
  {
    id: 'address',
    type: 'address',      
    text: 'Installation Address',
  },
  {
    id: 'utility',
    type: 'select',      
    text: 'Who is your Utility Company? (Select N/A if Off-Grid)',
    label: 'Select Your Electric Utility Company',
    select: utilityCompanies.map((option, index) => (
      <option key={index} value={option}>{option}</option>
    )),
  },
  {
    id: 'electricitybill',
    type: 'select',      
    text: 'On average, how much is your Electricity Bill?',
    label: 'Round the cost to the nearest 100s',
    select: utilityCostOptions.map((option, index) => (
      <>
      { index === 0 ?
        <option key={index} value={option}>{option}</option> 
        :
        <option key={index} value={option}>${option}</option>
      }        
      </>
    )),
    },
    {
      id: 'offset',
      type: 'multipleChoice',      
      text: 'How much do you want to offset your utility bill?',
      select: ["50%", "75%", "100%"]
    },
    {
      id:'mount',
      type:'multipleChoiceWithImages',      
      text: "Are we looking for Ground Mount or Roof Mount?",
      text2: "(Click both if you are interested in doing a combination of both)",
      select:[
        {displayValue:"Ground Mount", value:"ground_mount"},
        {displayValue:"Roof Mount", value:"roof_mount"}   
      ],
      images:[groundMountPicture, roofMountPicture],
      followUpRequiredValue: "roof_mount",
      followUp: {
        id:'type_of_roof',
        type: 'select',
        text: "What kind of roof are we working with?",
        select: roofTypes.map((option, index) => (
          <option key={index} value={option}>{option}</option>
        ))
      }
    },
    {
      id: 'preferredcommunication',
      type: 'multipleChoice',      
      text: 'Preferred form of Communication',
      select: ["Email", "Phone Call", "Text"],
      followUp: {
        text:"",
        id:null,
        type:null,
      }
    },
    {
      id: 'customerComment',
      type: 'input',
      text: "Please share any details that will help us provide you with the best possible service.",
    }
  ];

  
export default commercialQuestionData;