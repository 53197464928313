import React from 'react';
import { motion } from 'framer-motion';

import OffGridInfo from './children-OffGrid/OffGridInfo.js';
import Mounting from './children-OffGrid/Mounting.js';
import System from './children-OffGrid/System.js';
import Products from './children-OffGrid/Products.js';
import Benefits from './children-OffGrid/Benefits.js';
import Next from './children-OffGrid/Next.js';

const OffGrid = () => {

  const content = [
    OffGridInfo,
    Mounting,
    System,
    Benefits,
    Products,
    Next
  ]

  const containerVariants = {
      hidden: { opacity: 1 },
      visible: {
        opacity: 1,
        transition: {
          // This will stagger the children's individual animations by 0.3 seconds.
          staggerChildren: 0.3
        }
      }
    };

    const childVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 }
    };

    return(
    <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        className="bannerContainer"
      >

      {content.map((Component, index) => (
        <motion.div
          variants={childVariants}
          transition={{duration:0.3}}
          onAnimationComplete={index=== 0 ? () => {
            document.getElementById('offGrid').scrollIntoView({
              behavior:"smooth"
            })
          } : undefined}
          className={`container${index % 2 + 1}`}
          style={{
            "borderRadius":"15px",
            "fontSize":"x-large"
          }}
        >
          <Component />
        </motion.div>
      ))}
    </motion.div>
    )
}

export default OffGrid;

