import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setCart } from "../../Redux_Toolkit/cartSlice";

const Cart = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const db = useSelector((state) => state.firebaseDB.products);

  function removeSpaces(str) {
    return str.replace(/[\s-,/]+/g, '');
  }

  let qty = [];
  for(let i = 0; i < 20; i++){
    qty.push(i)
  }

  let subTotalPrice = 0;

  const handleQtyChange = (e, cartItem) => {
    const newQuantity = parseInt(e.target.value, 10);

    dispatch(setCart({
      product:cartItem.productName,
      quantity: newQuantity,
      productType:cartItem.productType,
      quantityChange: "set",
    }))
  }

  const handleDeleteFromCart = (cartItem) => {
    dispatch(setCart({
      product:cartItem.productName,
      quantity: 0,
      productType:cartItem.productType,
      quantityChange: "set",
    }))
  }

  const handleMoreInfo = (e, cartItem) => {
    navigate(`../product/${removeSpaces(cartItem.productName)}`, {state: {...cartItem.product}});
  }

  const categories = Object.entries(db).map(([key, value]) => ({ key, value }));

  let cartEntries = cart.cart.length > 0 ? cart.cart.map(cartItem => {
    // Find the category index
    const categoryIndex = categories.findIndex(category => category.key === cartItem.productType);
    if (categoryIndex === -1) {
      return []; // Or handle the missing category case
    }
  
    const category = categories[categoryIndex].value;
  
    // Find the product in the category
    const productKey = Object.keys(category).find(key => key === cartItem.product);
    const product = category[productKey];
  
    if (!product) {
      return []; // Or handle the missing product case
    }
  
    return {
      product,
      quantity: cartItem.quantity,
      productName: cartItem.product,
    };
  }) : [];

  return(
    <>
      <button 
          className="cartBackButton" 
          onClick={() => navigate("/Overall_Products/Popular")}
      >
          Back
      </button>
      {cartEntries.length === 0 ? (
        <>
          <h2 className="yourCartH2">Your Cart</h2>
          <div className="productForSale">
            <h2>Your Cart is Empty</h2>
          </div>
        </>
      ) : (
        <div className="cartCheckOutContainer">
          <div className="productForSale cart">
            <div className="cartHeader">
              <h2>Your Cart</h2>
              <h2>Price</h2>
            </div>
            <hr/>
            {cartEntries.map((cartItem, index) => (
              <div className="cartItem">
                  <div className="cartImage">
                    <img src={cartItem.product.frontImg} alt="pic"/>
                  </div>
                  <div className="productDescription cartDes">
                    <h2 onClick={(e) => handleMoreInfo(e, cartItem)} className="cartMoreInfo">{cartItem.productName}</h2>
                    <div className="cartItemOptions">
                      <div>
                        <span>Qty:</span><select value={cartItem.quantity} onChange={(e) => handleQtyChange(e, cartItem)}>
                          {qty.map((value, index) => (
                              <option key={index} value={value}>{value}{value === 0 ? (<span>(Delete)</span>): null}</option>
                          ))}
                        </select>                      
                      </div>
                      <button onClick={() => {handleDeleteFromCart(cartItem)}} className="delete" >Delete</button>
                    </div>
                  </div>
                  <div className="cartPrice">
                    <h2><span className="dollarSign">$</span>{removeSpaces(cartItem.product.retail) * cartItem.quantity}</h2>
                  </div>
              </div>                    
            ))}
            <hr/>
            <div className="cartFooter">
              <h2>Subtotal</h2>
              <h2>
                {
                  cartEntries.forEach((cartItem) => {
                    subTotalPrice += parseInt(removeSpaces(cartItem.product.retail), 10) * parseInt(cartItem.quantity, 10);
                  })
                }
                <span className="dollarSign">$</span>
                {subTotalPrice}
              </h2>
            </div>
          </div>
          <div className="checkOutContainer">
            <h2>Subtotal:</h2>
            <h2><span className="dollarSign">$</span>{subTotalPrice}</h2>
            <button className="checkoutButton" onClick={() => navigate('../checkout')}>Checkout</button>
          </div>
        </div>
      )}
    </>
  )
}

export default Cart;