import React from "react";

const BatteryInfo = () => {

    return(
      <div className="BGT banner1BGT">
        <h1 id="gridTiedBatteries">Batteries In Your House</h1>
        <h2>How It Works</h2>
        <p>
          After installing solar panels, excess energy is sold to the 
          utility company at their wholesale rate while energy purchased 
          by the customer is sold to them at retail. This is an 
          exceptionally unfavorable arrangement for the customer. 
          The solution? Your own Home Battery.
        </p>
        <p>
          During the day, the system charges a home battery with excess 
          energy from solar production. Once night falls, batteries begin 
          to discharge providing power to your home. When your battery has 
          discharged to a specific percentage, the system will then switch 
          back to grid power to supply the house.
        </p>
      </div>
    )
}

export default BatteryInfo;