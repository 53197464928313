import React from "react";
import { Link } from "react-router-dom";

import GetQuote from '../education-images/GetQuote.jpg';
import offGridPicture from '../education-images/OffGrid.jpg';

const Next = () => {

    return(
        <div className="BGT banner6BGT">
            <div>
            <h1>What is Next?</h1>
            <div className="options">
                <Link to="../../Quote">
                <div className="option">
                    <img src={GetQuote} alt="pic" />
                    <h3>Get a Quote?</h3>
                </div>                 
                </Link>
                <Link to="../offGrid">
                <div className="option">
                    <img src={offGridPicture} alt="pic" />
                    <h3>Going Off-Grid?</h3>
                </div>                 
                </Link>
            </div>
            
            </div>
        </div>
    )
}

export default Next;