import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getFirestore, collection, getDocs } from "firebase/firestore";

// Async thunk for fetching data from Firestore
export const fetchData = createAsyncThunk(
  'firebaseDB/fetchData',
  async () => {
    const db = getFirestore();
    const querySnapshot = await getDocs(collection(db, "Products"));
    let result = {};
    querySnapshot.docs.forEach(doc => {
      result[doc.id] = doc.data();
    });

    const querySnapshot2 = await getDocs(collection(db, "WebsiteText"));
    querySnapshot2.docs.forEach(doc => {
      result[doc.id] = doc.data();
    });
    return result;
  }
);

const initialState = {
  products: [],
  loading: false,
  error: null,
};

export const firebaseSlice = createSlice({
  name: 'firebaseDB',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchData.pending]: (state) => {
      state.loading = true;
    },
    [fetchData.fulfilled]: (state, action) => {
      state.loading = false;
      state.products = action.payload;
    },
    [fetchData.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
  },
});

// Export the reducer
export default firebaseSlice.reducer;
