import React from "react";

import procedurePicture1 from '../education-images/Procedure/GridTiedProcedure1.jpg';
import procedurePicture2 from '../education-images/Procedure/GridTiedProcedure2.jpg';
import procedurePicture3 from '../education-images/Procedure/GridTiedProcedure3.jpg';
import procedurePicture4 from '../education-images/Procedure/GridTiedProcedure4.jpg';

const TheWholeProcedure = () => {
    return(
      <div className="banner1GT">
        <h2><u>The Whole Procedure</u></h2>   
          <h3>
            Before any contract is signed, we inspect the house 
            and electricity bill to provide the customer an educated 
            insight on how many solar panels they need/want.
          </h3>
        <div className="doubleContainer">
          <div className="leftContainer">
            <ol>
              <li>
                <strong>Inspect House</strong>
                <p>
                  <strong>Size of Main Breaker</strong> - If it is too small, 
                  we are required to switch the whole panel to a standard 200A 
                  Main Service Panel.
                </p>
                <p>
                  <strong>Roof Space</strong> - We use our modeling software to 
                  design a system based on the available roof space.
                </p>
                <p>
                  <strong>Electricity Bill</strong> - Knowing the power consumption 
                  on average allows us to estimate how big the system needs to be.
                </p>
              </li>
              <li>
                <strong>Choose Desired System</strong>
                <p>
                  <strong>Offset</strong> - After speaking with us, 
                  the customer then has a choice to choose how much they want 
                  to offset their electricity bill.
                </p>
              </li>
                <li>
                  <strong>Signing The Contract</strong>
                  <p>
                    Once the contract is signed, everything is turn-key from there. 
                    Simple Indy will take care of permitting, interconnection with 
                    the utility, and any other compliance documentation necessary. 
                  </p>
                </li>
                <li>
                  <strong>Installation</strong>
                  <p>
                    Based on the size of the system, installation will 
                    take between 2 to 10 business days.
                  </p>
                </li>
                <li>
                  <strong>Utility Meter Swap</strong>
                  <p>
                    The utility company will swap the meter for a 
                    bi-directional meter to allow accurate monitoring 
                    of power flowing in and out of the house.
                  </p>
                </li>
            </ol>        
          </div>
          <div className="rightContainer">
                <img src={procedurePicture1} alt="pic"/>
                <img src={procedurePicture2} alt="pic"/>
                <img src={procedurePicture3} alt="pic"/>
                <img src={procedurePicture4} alt="pic"/>
          </div>
        </div>
      </div>
    )
}

export default TheWholeProcedure