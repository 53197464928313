import React from "react";

import icon from './images-home/facebook.svg';
import instagramIcon from './images-home/Instagram_icon.png';

const Media = () => {
  return(
    <div className="media">
      <h1>Follow Us On Our Social Media</h1>
      <div className="icons">
        <a 
        href="https://www.facebook.com/simpleindyllc" 
        target="_blank" 
        rel="noopener noreferrer"
        >
          <img src={icon} alt="facebookIcon"/>
        </a>
        <a 
        href="https://www.instagram.com/simpleindyllc/?hl=en" 
        target="_blank" 
        rel="noopener noreferrer"
        >
          <img src={instagramIcon} alt="instagramIcon"/>
        </a>      
      </div>

    </div>
  )
}

export default Media;