import React from "react";

const BigQuestion = () => {
    return(
    <div className="banner2RV">
      <h2>Assessing Your Energy Requirements</h2>
      <p>Selecting the right energy system for your vehicle is critical. We provide expert guidance to determine the precise specifications to meet your needs.</p>
      <h3>Energy Calculation Framework</h3>
      <p>Consider the following to establish your energy profile:</p>
    </div>
    )
}

export default BigQuestion;