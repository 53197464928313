import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import Carrousel from './children-home/Carrousel.js';
import './styles/about.css'
import { useSelector } from 'react-redux/es/hooks/useSelector.js';

const About = () => {
  const db = useSelector((state) => state.firebaseDB.products);
  let history = "";
  let jobs = "";
  if(db){
    history = db.HistoryOfSimpleIndy;
    jobs = db.RecentJobs;
  }
  const carrouselCallValues = ["A1", "A2", "A3"];
  const navigate = useNavigate();

  const handleOnClick = (e) => {
    const endpoint = e.currentTarget.getAttribute('data-endpoint');
    navigate(`${endpoint}`);
    window.scroll(0, 0);
  }

  const variantContainer = {
    hidden: {opacity: 1},
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  }

  const variant = {
    hidden: {opacity: 0},
    visible: {opacity: 1}
  }

    // eslint-disable-next-line no-unused-vars
  const Jobs = () => {
    return(
        <>
        <motion.div
          variants={variant}
          transition={{duration:0.2}}
          className="recentJobTitle"
        >
          <h1>Recent Jobs</h1>
          <h2>Click any of the Jobs to Learn More</h2>              
        </motion.div>

        {jobs ? jobs.Jobs.map((job, index) => (
          <motion.div
            className="job"
            variants={variant}
            transition={{duration:0.2}}
          >
            <div className="jobName">
              <h2>{job.name}</h2>
            </div>
            <div className="jobContent">
              <img src={job.image1} alt={`${job.name} pic 1`}/>
              <div className="jobDescription">
                <h3>{`${job.numOfPanels} ${job.panelBrand} Panels - ${job.systemSize} System`}</h3>
                <h3>{`${job.offset} Offset`}</h3>
                <button 
                  className="seeMore"
                  data-endpoint={`/About_Us/JobArticle-${index + 1}`}
                  onClick={handleOnClick}
                >
                  See More About This Job
                </button>
              </div>
            </div>
          </motion.div>
        ))
        :
        ""}
        </>
      )
      }

    return(
        <motion.div
          variants={variantContainer}
          initial="hidden"
          animate="visible"
          style={{fontSize:"x-large"}}
        >
         
          <motion.div 
            className="historyStyle"
            variants={variant}
            transition={{duration:0.2}}
          >
            <h1>History of Simple Indy</h1>
            <p style={{fontSize:"large"}}>
            {history ? `${history.History}` : ""}
            </p>
          </motion.div>

            <motion.div
              variants={variant}
              transition={{duration:0.2}}
            >
              <Carrousel value={carrouselCallValues}/>
            </motion.div>
          
            <Jobs />
        </motion.div>
    );
}

export default About;