import React from "react";

import solar2 from '../education-images/Solar2.jpg';
import solar3 from '../education-images/Solar3.jpg';

const TypesOfSolar = () => {
    return(
        <div>
            <div className="banner2SP">
            <h1>Types of Solar Panels</h1>
            <div className="doubleContainer">
                <div className="leftContainer">
                    <h2>MonoCrystalline</h2>
                    <img src={solar2} alt="pic"/>
                    <h4><u>Pros of MonoCrystalline</u></h4>
                    <ul>
                    <li>
                        <strong>Higher Efficiency</strong>
                        <p> - Typically around 15% to 20%.</p>
                    </li>
                    <li>
                        <strong>Less Space For More Power</strong>
                        <p> - More efficient means more power per square foot</p>
                    </li>
                    <li>
                        <strong>Longevity</strong>
                        <p> - <strong>25</strong> Year Warranty</p>
                        <p>
                        - Even after 25 years, the panels will still operate
                        at around <strong>80-90%</strong> of the original capacity.
                        </p>
                    </li>
                    <li>
                        <strong>Performance in Warm Weather</strong>
                        <p>
                        - Solar Panels performance will dip as temperature rises, but 
                        monocrystalline panels are more effective than Polycrystalline panels
                        in warm weather.
                        </p>
                    </li>
                    </ul>
                </div>
                <div className="rightContainer">
                    <h2>Polycrystalline</h2>
                    <img src={solar3} alt="pic"/>
                    <h4><u>Pros of Polycrystalline</u></h4>
                    <ul>
                    <li>
                        <strong>Lower Price</strong>
                        <p>
                        - Polycrystalline panels are less expensive to produce, thus, sold
                            for less.
                        </p>
                    </li>
                    </ul>
                    <h4><u>Cons of Polycrystalline</u></h4>
                    <ul>
                    <li>
                        <strong>Lower Efficiency</strong>
                        <p>
                        - Efficieny ranges around 13-16%.
                        </p>
                        <p>
                        - Generates less power per square foot.
                        </p>
                    </li>
                    <li>
                        <strong>Space</strong>
                        <p>
                        - Need more panels to equal out power produced compared to 
                        MonoCrystalline Panels;
                        </p>
                    </li>
                    </ul>
                </div>                
            </div>
            </div>            
        </div>
    )
}

export default TypesOfSolar;

