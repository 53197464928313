import React from "react";
import { Link } from "react-router-dom";

import gridTiedPicture from '../education-images/gridTiedPicture.jpg';
import offGridPicture from '../education-images/OffGrid.jpg';
import rvPicture from '../education-images/RV.jpg';


const Next = () => {
    return(
        <div className="banner4SP">
            <h1>Next Step</h1>
            <h2>Now you know the basics of solar panels,</h2>
            <h2>Check out how we do installations</h2>
            <div className="options">
                <Link to="../gridTied">
                    <div className="option">
                        <img src={gridTiedPicture} alt="pic" />
                        <h3>Solar + Grid-Tied</h3>
                    </div>                
                </Link>


                <Link to="../offGrid">
                    <div className="option">
                        <img src={offGridPicture} alt="pic" />
                        <h3>Off Grid</h3>
                    </div>                
                </Link>

                <Link to="../rv">
                    <div className="option">
                        <img src={rvPicture} alt="pic" />
                        <h3>RV</h3>
                    </div>  
                </Link>
            </div>
        </div>            
    )
}

export default Next;