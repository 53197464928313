import React from 'react';
import { motion } from 'framer-motion';

import IntroToRv from './children-RV/IntroToRv.js';
import BigQuestion from './children-RV/BigQuestion.js';
import Q1HowManyBattery from './children-RV/Q1HowManyBattery.js';
import Q2GeneratorRunTime from './children-RV/Q2GeneratorRunTime.js';
import Q1And2Conclusion from './children-RV/Q1And2Conclusion.js';
import Q3BestEquipment from './children-RV/Q3BestEquipment.js';
import Next from './children-RV/Next.js';

const RV = () => {

  const content = [
    IntroToRv,
    BigQuestion,
    Q1HowManyBattery,
    Q2GeneratorRunTime,
    Q1And2Conclusion,
    Q3BestEquipment,
    Next
  ]

  const containerVariants = {
      hidden: { opacity: 1 },
      visible: {
        opacity: 1,
        transition: {
          // This will stagger the children's individual animations by 0.3 seconds.
          staggerChildren: 0.3
        }
      }
    };

    const childVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 }
    };

    return(
    <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        className="bannerContainer"
      >

      {content.map((Component, index) => (
        <motion.div
          variants={childVariants}
          transition={{duration:0.3}}
          onAnimationComplete={index===0 ? () => {
            document.getElementById('rv').scrollIntoView({
              behavior:"smooth"
            })
          }:undefined}
          className={`container${index % 2 + 1}`}
          style={{
            fontSize:"x-large",
            borderRadius:"15px"
          }}
        >
          <Component />
        </motion.div>
      ))}
    </motion.div>
    )
}

export default RV;

