import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useNavigate, Routes, Route, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import './styles/products.css';

import Shopping from './children-products/Shopping';
import Cart from './children-products/Cart';
import Checkout from './children-products/Checkout';
import Confirmation from './children-products/Confirmation';

import searchIcon from './children-products/images-products/search.png';
import cartIcon from './children-products/images-products/shopping-cart.png';

const OverallProducts = () => {

  const navigate = useNavigate();
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const db = useSelector((state) => state.firebaseDB.products);
  const [toggleSearchResults, setToggleSearchResults] = useState(false);
  const resultRef = useRef(null);

  const location = useLocation();
  const hiddenPaths = ['/Overall_Products/Cart', '/Overall_Products/checkout', '/Overall_Products/confirmation'];

  let productNames = useMemo(() => {
    let names = [];
    Object.entries(db).forEach(category => {
      Object.entries(category[1]).forEach(product => {
        names.push(product);
      })
    });
    return names;   
  }, [db])

  const handleToggleSearchBar = (e) => {
    setShowSearchBar(!showSearchBar);
    setSearchValue("");
  }

  const backToTop = () => {
    window.scrollTo({
      top:0,
      behavior:"smooth"
    });
  }

  useEffect(() => {
    if(searchValue.length > 2) {
      const lowercaseValue = searchValue.toLowerCase();
      const results = productNames.filter(product =>
        product[0].toLowerCase().includes(lowercaseValue)
      );
      setToggleSearchResults(true);
      setSearchResults(results.slice(0,5));
    } else {
      setSearchResults([]);
    }
  }, [searchValue, productNames])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (resultRef.current && !resultRef.current.contains(event.target)) {
        setToggleSearchResults(false);
      }      
    };

    document.addEventListener('mousedown', handleClickOutside);
    return() => {
      document.removeEventListener('mousedown', handleClickOutside);
    }
  }, []);

  function removeSpaces(str) {
    return str.replace(/[\s-,/]+/g, '');
  }

  const handleClickSearchResult = (e, product) => {
    navigate(`./product/${removeSpaces(product[0])}`, {state: {...product[1]}})
    setSearchValue("");
/*     document.getElementById("cart").scrollIntoView({
      behavior:"smooth"
    }) */
  }
    
  return(
    <div className="shopContainer">
      <div className="iconContainer">
        <input 
          type="text" 
          className={showSearchBar ? "searchBar" : "hideSearchBar"}
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
          placeholder='Search...'
        />
        <div className='displaySearch'>
          {searchResults && toggleSearchResults ? searchResults.map((product) => (
            <div 
              className="searchProduct" 
              onMouseDown={(e) => handleClickSearchResult(e, product)}
              ref={resultRef}  
            >
              <h3>{product[0]}</h3>
            </div>
          )) : ""}
        </div>
        <img 
          src={searchIcon} 
          alt="search icon"
          onClick={handleToggleSearchBar}
        />
        <img 
          src={cartIcon} 
          alt="cart icon"
          onClick={() => navigate('./Cart')}
          id={hiddenPaths.includes(location.pathname) ? "" : "cartIcon"}
        />
      </div>
      <Routes>
        <Route path='/*' element={<Shopping />}/>
        <Route path='/cart' element={<Cart />} />
        <Route path='/checkout' element={<Checkout />} />
        <Route path='/confirmation' element={<Confirmation />} />
      </Routes>
      <button className="backToTop" onClick={backToTop}>Back To Top</button>
    </div>
  )
}

export default OverallProducts;