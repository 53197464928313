import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { Routes, Route, NavLink } from 'react-router-dom';

import './styles/education.css';
import Solar from './children-education/education-images/Solar.jpg';
import gridTiedPicture from './children-education/education-images/gridTiedPicture.jpg';
import offGridPicture from './children-education/education-images/OffGrid.jpg';
import rvPicture from './children-education/education-images/RV.jpg';
import gridTiedBatteryPicture from './children-education/education-images/GridTiedBattery.jpg';

import SolarPanels from './children-education/SolarPanels.js';
import GridTied from './children-education/GridTied.js';
import OffGrid from './children-education/OffGrid.js';
import RV from './children-education/RV.js';
import BatteriesGT from './children-education/BatteriesGT';


const Education = () => {

  const variantContainer = {
    hidden: {opacity: 1},
    visible: {
      opacity: 1,
      transition: {
          staggerChildren: 0.3
      }
    }
  }

  const variant = {
    hidden: {opacity: 0},
    visible: {opacity: 1},
  }

  const backToTop = () => {
    window.scrollTo({
      top:0,
      behavior:"smooth"
    });
  }

    return (
      <div className="educationPage">
        <h1 className="educationPageH1">Education</h1>
        <h2 className="educationPageH2">Which category would you like to learn more about?</h2>
        <motion.div 
            className="options"
            variants={variantContainer}
            initial="hidden"
            animate="visible"    
        >
            <NavLink to="./solarPanels" activeClassName={"active"}>
                <motion.div 
                    className="option"
                    variants={variant}
                    transition={{duration:0.3}}
                >   
                    <img src={Solar} alt="pic"/>
                    <h2>Solar Panels</h2>
                </motion.div>
            </NavLink>

            <NavLink to="./gridTied" activeClassName={"active"}>
                <motion.div 
                    className="option" 
                    variants={variant}
                    transition={{duration:0.3}}
                >
                    <img src={gridTiedPicture} alt="pic" />
                    <h2>Solar + Grid-Tied</h2>
                </motion.div>                    
            </NavLink>

            <NavLink to="./gridTiedBatteries" activeClassName={"active"}>
                <motion.div 
                    className="option" 
                    variants={variant}
                    transition={{duration:0.3}}
                >
                    <img src={gridTiedBatteryPicture} alt="pic" />
                    <section>
                        <h3>Batteries + Grid-Tied</h3>
                        <h3>(Highly Recommended)</h3>
                    </section>
                </motion.div> 
            </NavLink>

            <NavLink to="./offGrid" activeClassName={"active"}>
                <motion.div 
                    className="option" 
                    variants={variant}
                    transition={{duration:0.3}}
                >
                    <img src={offGridPicture} alt="pic" />
                    <h2>Off Grid</h2>
                </motion.div>                    
            </NavLink>
            <NavLink to="./rv" activeClassName={"active"}>
                <motion.div 
                    className="option" 
                    variants={variant}
                    transition={{duration:0.3}}
                >
                    <img src={rvPicture} alt="pic" />
                    <h2>RV</h2>
                </motion.div>                    
            </NavLink>
        </motion.div>
        <div className="educationalContent">
        <AnimatePresence>
            <motion.div
                //key={toggle}  // Ensure each state has a unique key
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{duration:0.3, ease: "easeInOut"}}
            >
                <Routes>
                    <Route path="solarPanels" element={<SolarPanels />}/>
                    <Route path="gridTied" element={<GridTied />}/>
                    <Route path="offGrid" element={<OffGrid />}/>
                    <Route path="rv" element={<RV />}/> 
                    <Route path="gridTiedBatteries" element={<BatteriesGT/>} />                           
                </Routes>
            </motion.div>
        </AnimatePresence>
        <button className="backToTop" onClick={backToTop}>Back To Top</button>
        </div>
      </div>
    )
}

export default Education;