import React from "react";
import { useNavigate } from 'react-router-dom';
//import victronBanner from '../children-about/images-about/CarouselContent1.jpg'

const Product = () => {

  const navigate = useNavigate();

  const handleGoToProducts = () => {
    navigate('./Overall_Products');
    window.scroll(0,0);
  }

  return(
    <div className="productsBanner">
      <h1>Buy Through the Website</h1>
      <h1>Have $3,000 in your cart and get 10% OFF Your Order</h1>
      <h1>$5,000 for 15% OFF</h1>
      <button onClick={handleGoToProducts}>Shop Here</button>
    </div>
  )
}

export default Product