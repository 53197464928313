import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import About from "./components/About";
import JobArticle1 from "./components/children-about/JobArticle1";
import JobArticle2 from "./components/children-about/JobArticle2";
import JobArticle3 from "./components/children-about/JobArticle3";
import Contact from "./components/Contact";
import Quote from "./components/Quote";
import OverallProducts from "./components/OverallProducts";
import Education from "./components/Education";
import PrivacyPolicy from "./components/PrivacyPolicy";

const Body = () => {
  return(
  <body className="solarBackground">
    <div className="content">
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/About_Us" element={<About />} />
        <Route path="/About_Us/JobArticle-1" element={<JobArticle1 />} />
        <Route path="/About_Us/JobArticle-2" element={<JobArticle2 />} />
        <Route path="/About_Us/JobArticle-3" element={<JobArticle3 />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Quote/*" element={<Quote />} />
        <Route path="/Overall_Products/*" element={<OverallProducts />} />
        <Route path="/Education/*" element={<Education />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
      </Routes>

    </div>
  </body>
  )
}

export default Body;