import React, { useState } from "react";
import { Route, Routes, NavLink, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";

import Category from "./Category";

import Product from './Product';
import Ad from './Ad';

const Shopping = () => {
  const db = useSelector((state) => state.firebaseDB.products);
  const [toggleMobileMenu, setToggleMobileMenu] = useState(false);
  const [toggles, setToggles] = useState({
    invert: false,
    battery: false,
    solar: false,
    monitoring: false,
    accessories: false,
  });
  const filters = Object.keys(db);

  const handleCategoryToggle = () => {
    setToggleMobileMenu(!toggleMobileMenu)
    if(toggleMobileMenu === false){
      handleTurnOffAllToggle();
    }
  }

  const handleToggle = (toggle) => {
    setToggles(prev => ({
      ...prev,
      [toggle]: !prev[toggle]
    }))
  }

  const handleTurnOffAllToggle = () => {
    setToggles({
      invert: false,
      battery: false,
      solar: false,
      monitoring: false,
      accessories: false,
    })
  }

  const handleMoveToSeriesFilter = () => {
    setTimeout(() => {
      document.getElementById("seriesFilter").scrollIntoView({
        behavior:"smooth",
      })
    }, 300);

  }

  const containerVariants = {
    hidden: { opacity: 1 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren:0.3
      }
    }
  }

  const childVariants = {
    hidden: {opacity: 0 },
    visible: {opacity: 1}
  }

  return(
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <Ad />
      <motion.div 
        variants={childVariants}
        transition={{duration:0.3}}
        className="advertisement"
      >
        <h2>
          Get Your Victron Energy Equipment Here
        </h2>
        <h2>
          Buying from us not only gets you the Amazing Products, but also our Excellent Tech Support
        </h2>
      </motion.div>
      <motion.div 
        variants={childVariants}
        transition={{duration:0.3}}
        className="productContainer"
      >
        <NavLink to="Popular" className="popular" activeClassName={"active"} onClick={handleMoveToSeriesFilter}>Top 10 Items Customers Buy</NavLink>
        <div className="filterContainerMobile">
          <div className="categoriesMobile" onClick={handleCategoryToggle}>
            <h2>Category</h2>
            <h2>{toggleMobileMenu ? "▲" : "▼"}</h2>
          </div>
          <div className={toggleMobileMenu ? "categoryMenu" : "hideCategoryMenu"}>
            
            <div className="categoryMobile2" onClick={() => handleToggle("invert")}>
              <h3>Invert And Charge</h3>
              <h3>{toggles.invert ? "▲" : "▼"}</h3>
            </div>
            <div
              className={toggles.invert ? "categoryOpenMenu" : "hideCategoryMenu"}
              onClick={handleMoveToSeriesFilter}  
            >
              {
                toggles.invert ? 
                filters.map((filter, index) => (
                  filter.includes("Inv") || filter.includes("DC to DC") || filter.includes("EV Charger") ? 
                  <NavLink to={(filter)} className="filter" activeClassName={"active"}>{filter}</NavLink> 
                  : ""
                ))
                :
                ""
              }              
            </div>

            <div className="categoryMobile1" onClick={() => handleToggle("battery")}>
              <h3>Batteries</h3>
              <h3>{toggles.battery ? "▲" : "▼"}</h3>
            </div>
            <div className={toggles.battery ? "categoryOpenMenu" : "hideCategoryMenu"} onClick={handleMoveToSeriesFilter}>
              {
                toggles.battery ? 
                filters.map((filter, index) => (
                  filter.includes("Batter") ? 
                  <NavLink to={(filter)} className="filter" activeClassName={"active"}>{filter}</NavLink>
                   : ""
                ))
                :
                ""
              }              
            </div>

            <div className="categoryMobile2" onClick={() => handleToggle("solar")}>
              <h3>Solar</h3>
              <h3>{toggles.solar ? "▲" : "▼"}</h3>
            </div>
            <div className={toggles.solar ? "categoryOpenMenu" : "hideCategoryMenu"} onClick={handleMoveToSeriesFilter}>
              {
                toggles.solar ? 
                filters.map((filter, index) => (
                  filter.includes("Solar") ? 
                  <NavLink to={(filter)} className="filter" activeClassName={"active"}>{filter}</NavLink>
                   : ""
                ))
                :
                ""
              }              
            </div>
            <div className="categoryMobile1" onClick={() => handleToggle("monitoring")}>
              <h3>Monitoring</h3>
              <h3>{toggles.monitoring ? "▲" : "▼"}</h3>
            </div>
            <div className={toggles.monitoring ? "categoryOpenMenu" : "hideCategoryMenu"} onClick={handleMoveToSeriesFilter}>
              {
                toggles.monitoring ? 
                filters.map((filter, index) => (
                  filter.includes("GX") || filter.includes("Remote") || filter.includes("Meters") ? 
                  <NavLink to={(filter)} className="filter" activeClassName={"active"}>{filter}</NavLink>
                   : ""
                ))
                :
                ""
              }              
            </div>
            <div className="categoryMobile2" onClick={() => handleToggle("accessories")}>
              <h3>Accessories</h3>
              <h3>{toggles.accessories ? "▲" : "▼"}</h3>
            </div>
            <div className={toggles.accessories ? "categoryOpenMenu" : "hideCategoryMenu"} onClick={handleMoveToSeriesFilter}>
              {
                toggles.accessories ? 
                filters.map((filter, index) => (
                  !filter.includes("EV") && 
                  !filter.includes("Pop") && 
                  !filter.includes("Solar") && 
                  !filter.includes("Inv") && 
                  !filter.includes("DC to DC") && 
                  !filter.includes("Batter") && 
                  !filter.includes("GX") && 
                  !filter.includes("Remote") && 
                  !filter.includes("Meters") &&
                  !filter.includes("Contact") &&
                  !filter.includes("HistoryOf") &&
                  !filter.includes("OurTeam") &&
                  !filter.includes("RecentJobs") &&
                  !filter.includes("SimpleIndyNews") 
                  ? 
                  <NavLink to={(filter)} className="filter" activeClassName={"active"}>{filter}</NavLink>
                   : ""
                ))
                :
                ""
              }              
            </div>
          </div>
        </div>
        {/* ///////////////////////////////----------------------------------------------------------------------------------------------------//////////////////////////////////// */}
        <div className="filterContainer">
          <div className="productFilters">
            <h3>Invert and Charge</h3>
            {filters.map((filter, index) => (
              filter.includes("Inv") || filter.includes("DC to DC") || filter.includes("EV Charger") ? 
              <NavLink to={(filter)} className="filter" activeClassName={"active"} onClick={handleMoveToSeriesFilter} key={filter}>{filter}</NavLink> 
              : ""
            ))}
          </div>
          <div className="productFilters">
            <h3>Batteries</h3>
            {filters.map((filter, index) => (
              filter.includes("Batter") ? 
              <NavLink to={(filter)} className="filter" activeClassName={"active"} onClick={handleMoveToSeriesFilter} key={filter}>{filter}</NavLink>
               : ""
            ))}
          </div>
          <div className="productFilters">
            <h3>Solar</h3>
            {filters.map((filter, index) => (
              filter.includes("Solar") ? 
              <NavLink to={(filter)} className="filter" activeClassName={"active"} onClick={handleMoveToSeriesFilter} key={filter}>{filter}</NavLink>
               : ""
            ))}
          </div>
          <div className="productFilters">
            <h3>Monitoring</h3>
            {filters.map((filter, index) => (
              filter.includes("GX") || filter.includes("Remote") || filter.includes("Meters") ? 
              <NavLink to={(filter)} className="filter" activeClassName={"active"} onClick={handleMoveToSeriesFilter} key={filter}>{filter}</NavLink>
               : ""
            ))}
          </div>
          <div className="productFilters">
            <h3>Accessories</h3>
            {filters.map((filter, index) => (
              !filter.includes("EV") && 
              !filter.includes("Pop") && 
              !filter.includes("Solar") && 
              !filter.includes("Inv") && 
              !filter.includes("DC to DC") && 
              !filter.includes("Batter") && 
              !filter.includes("GX") && 
              !filter.includes("Remote") && 
              !filter.includes("Meters") &&
              !filter.includes("Contact") &&
              !filter.includes("HistoryOf") &&
              !filter.includes("OurTeam") &&
              !filter.includes("RecentJobs") &&
              !filter.includes("SimpleIndyNews") 
              ? 
              <NavLink to={(filter)} className="filter" activeClassName={"active"} onClick={handleMoveToSeriesFilter} key={filter}>{filter}</NavLink>
               : ""
            ))}
          </div>
        </div>

        <div className="listOfProducts">
          <Routes>
            <Route path="/" element={<Navigate replace to="/Overall_Products/Popular" />} />
            <Route path="/:categoryId" element={<Category />} />
            <Route path="/product/:productID" element={<Product />} />
          </Routes>
        </div>
      </motion.div>
    </motion.div>
  )
}

export default Shopping;