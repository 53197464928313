import React from "react";

import RV11 from '../education-images/RV11.png'
import RV12 from '../education-images/RV12.webp'
import RV13 from '../education-images/RV13.jpg'
import RV14 from '../education-images/RV14.png'

const Q3BestEquipment = () => {
    return(
<div className="banner6RV">
  <h2>Defining Excellence in Energy Systems</h2>
  <div className="Victron">
    <h2>Our Preferred Partner: Victron Energy</h2>
    <img src={RV14} alt="Victron Energy products range"/>
  </div>
  
  <h3>The Victron Advantage:</h3>
  <div className="banner4RV">
    <ul className="descriptionRV">
      <li><strong>Comprehensive 5-Year Warranty</strong> on all equipment, ensuring peace of mind and long-term reliability.</li>
      <li>
        <strong>Remote Monitoring Portal</strong> for streamlined diagnostics and support, accessible via a simple phone call or email.
      </li>
      <li><strong>User-Friendly Interface</strong> for hassle-free operation without the need for constant reference to a manual.</li>
      <li><strong>State-of-the-Art Equipment</strong> that allows complete visibility and control over your energy system.</li>
      <li><strong>Ownership and Control</strong> over your system, empowering you to manage your energy independence.</li>
    </ul>
    <div className="imagesRV">
      <img src={RV11} alt="Victron Energy user interface"/>
      <img src={RV12} alt="Victron Energy mobile app"/>
      <img src={RV13} alt="Victron Energy monitoring system"/>
    </div>             
  </div>
</div>

    )
}

export default Q3BestEquipment;