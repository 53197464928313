import React from 'react';
import { motion } from 'framer-motion';
//import placeholder from './contact-photos/Kendall.jpg';
import './styles/contact.css';
import { useSelector } from 'react-redux/es/hooks/useSelector';

const Contact = () => {

  const db = useSelector((state) => state.firebaseDB.products)
  let contacts = "";
  let employees = "";
  if(db.OurTeam){
    contacts = db.Contact;
    employees = db.OurTeam.Employees;
  }

  const containerVariants = {
      hidden: { opacity: 1 },
      visible: {
      opacity: 1,
      transition: {
          // This will stagger the children's individual animations by 0.3 seconds.
          staggerChildren: 0.3
      }
      }
  };

  const variant = {
      hidden: { opacity: 0 },
      visible: { opacity: 1 }
  }


  return(
    <motion.div 
        className="container contact" 
        style={{fontSize:"x-large"}}
        initial="hidden"
        animate="visible"
        variants={containerVariants}
    >
          <h2>Contact</h2>
          {db ? (<motion.div 
              className="office"
              variants={variant}
              transition={{duration:0.2}}
          >
            <img src={contacts["Modified URL"]} alt="Office Pic" />
            <div className="officeContact">
                <p style={{"fontWeight":"bold"}}>Office Hours</p>
                <p>{contacts["Office Hours"]}</p>
                <p>Email: {contacts.Email}</p>
                <p>Phone: {contacts.Phone}</p>
                <p>{contacts["Location 1"]}</p>
                <p>{contacts["Location 2"]}</p>
            </div>
              
          </motion.div>) : ""}

          <h2>Our Team</h2>
          <div className="list">
            {employees ? employees.map(employee => 
              employee.name ? (
                <motion.div
                className="person"
                variants={variant}
                transition={{duration:0.2}}
              >
                <img src={employee.image} alt={`${employee.name}'s Pic`} />
                <section className="description">
                  <p style={{"fontWeight":"bold"}}>{employee.name}</p>
                  <p>{employee.role}</p>
                  <p>{employee.email}</p>
                </section>              
              </motion.div>)
              : "") 
            : ""}

          </div>
          
      </motion.div>
    )
  }

export default Contact;