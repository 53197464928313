import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/home.css';
import AboutUs1 from './images-home/AboutUs1.jpg';
import AboutUs2 from './images-home/AboutUs2.jpg';
import AboutUs4 from './images-home/AboutUs4.png';

const AboutUs = () => {
    const navigate = useNavigate();

    const handleGoToAbout = () => {
        navigate('./About_Us');
        window.scrollTo(0, 0);
    }

    const handleOnClickDiv = (e) => {
        navigate(`./${e.currentTarget.getAttribute("data-value")}`);
        window.scrollTo(0,0);
    }

    return(
        <div className="about-banner">
            <h1>Why Simple Indy?</h1>
            <div className="about-container">
                <div className="info-container" data-value="About_Us" onClick={handleOnClickDiv}>
                    <img src={AboutUs1} alt="pic" />
                    <span className="info">Craftsmanship</span>
                    <span>
                        Perfection in craftsmanship isn't just our standard — it's our commitment to your vision
                    </span>
                </div>
                <div className="info-container" data-value="About_Us" onClick={handleOnClickDiv}>
                    <img src={AboutUs2} alt="pic" />
                    <span className="info">Purpose</span>
                    <span>
                        Harnessing the sun's energy with intention, for a planet we love and a future we believe in
                    </span>
                </div>
                <div className="info-container">
                    <img src={AboutUs4} alt="pic" />
                    <span className="info">Top-Tier Products</span>
                    <span>
                        Elevate your expectations; our top-tier products redefine the meaning of excellence
                    </span>
                </div> 
            </div>
            <div className="link">
                <button onClick={handleGoToAbout}>Learn More About Us</button>
            </div>
        </div>
        
    )
}

export default AboutUs;