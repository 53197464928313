import React, {useState} from "react";
import { useNavigate } from "react-router-dom";
import "./styles/home.css";
import { motion } from "framer-motion";

import News from "./children-home/News.js";
import Carrousel from './children-home/Carrousel.js';
import GetAQuote from './children-home/GetAQuote.js';
import AboutUs from './children-home/AboutUs.js';
/* import Education from './children-home/Education.js'; */
import WhatWeOffer from "./children-home/WhatWeOffer.js";
import Logos from "./children-home/Logos.js";
import Product from "./children-home/Product.js";
//import Questions from './children-home/Questions.js';

const Home = () => {

  const carrouselCallValues = ["H1", "H2", "H3"];
  const navigate = useNavigate();
  const [togglePolicyPopUp, setTogglePolicyPopUp] = useState(true);

  const sections = [WhatWeOffer, News, Product, Carrousel, GetAQuote, Logos, AboutUs/* , Education */ ];

  const scrollToTop = () => {
    window.scroll({
      top:0,
      behavior:'smooth'
    })
  }

  const variantContainer = {
    hidden: {opacity: 1},
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3
      }
    }
  }

  const variant = {
    hidden: {opacity: 0},
    visible: {opacity: 1}
  }

  return (
    <motion.div 
      className="overall-container"
      variants={variantContainer}
      initial="hidden"
      animate="visible"
  >
      {sections.map((Section, index) => (
        <motion.div 
          className="sectionContainer"
          variants={variant}
          transition={{duration:0.3}}
          key={Section}
        >
        <Section value={carrouselCallValues}/> 
        </motion.div>
      ))}
      <div className={togglePolicyPopUp ? "privacyPopUp" : "closedPopUp"}>
        <h3>Read our Privacy Policy here at anytime</h3>
        <div className="buttons">
          <button onClick={() => {
            navigate('/PrivacyPolicy');
            scrollToTop();
          }}>Privacy Policy</button>
          <button className="x" onClick={() => setTogglePolicyPopUp(false)}>✕</button>
        </div>
      </div>
    </motion.div>
    

  );
}

export default Home;
