import solArkInverter from './quote-images/QOF-2.png';
import victronInverter from './quote-images/QOF-3.png';
import homeGridBattery from './quote-images/QOF-4.webp';
import linioTechBattery from './quote-images/QOF-7.png';
import reLiOnBattery from './quote-images/QOF-8.jpg';

import groundMountPicture from './quote-images/QR-3.jpg';
import roofMountPicture from './quote-images/QR-2.jpg';
/* import solArkWithMonitor from './quote-images/QOF-5.jpg';
import vrm from './quote-images/QOF-6.webp';
 */
const utilityCostOptions = [];
for (let i = 0; i <= 300; i += 10) {
    utilityCostOptions.push(i);
}

const batteryBackUpHours = [];
for (let i = 0; i <= 48; i += 1) {
  batteryBackUpHours.push(`${i} Hours`);
}

const dailyPowerUsage = [];
dailyPowerUsage.push("---Select---")
for (let i = 0; i <= 100; i += 5) {
  dailyPowerUsage.push(`${i} kWh`);
}

const monthlyPowerUsage = [];
monthlyPowerUsage.push("---Select---")
for (let i = 300; i <= 1600; i += 50) {
  monthlyPowerUsage.push(`${i} kWh`);
}

const sustainabilityOptions = [
  "---Select---", 
  "Never", 
  "Once a week", 
  "Every other Day", 
  "Everyday"
];

const roofTypes = ["Shingles", "Metal", "Flat Rubber"];

const offgridQuestionsData = [
  {
    id: 'address',
    type: 'address',      
    text: 'Installation Address',
  },
    {
      id:'inverterBrand',
      type:'multipleChoiceWithImages',      
      text: "Which brand are you interested in?",
      select:[
        {displayValue:"Victron", value:"victron"},
        {displayValue:"Sol-Ark", value:"solArk"}
      ],
      images:[victronInverter, solArkInverter],
      followUpRequiredValue: "victron",
      followUp: {
        id:'vrmOnlineMonitoring',
        type: 'select',
        text: "Would you like to utilize Victron's online monitoring?",
        select: [
          (<option key="---Select---">---Select---</option>),
          (<option key="Yes" value="Yes">Yes</option>),
          (<option key="No" value="No">No</option>)
        ]
      }
    },
    {
      id:'battery',
      type:'multipleChoiceWithImages',
      text: "Which battery brand are you interested in?",
      select:[
        {displayValue:"HomeGrid", value:"homeGrid"},
        {displayValue:"Liniotech", value:"liniotech"},
        {displayValue: "ReLiOn", value:"reLiOn"}
      ],
      images:[homeGridBattery, linioTechBattery, reLiOnBattery],
      className:"offGridBatteries"
    },
    {
      id:'battery_hours',
      type:'select',
      text: "Desired Amount of Back Up Hours?",
      select: batteryBackUpHours.map((option, index) => (
        <option key={index} value={option}>{option}</option>
      ))
    },
    {
      id:'mount',
      type:'multipleChoiceWithImages',      
      text: "Are we looking for Ground Mount or Roof Mount?",
      text2: "(Click both if you are interested in doing a combination of both)",
      select:[
        {displayValue:"Ground Mount", value:"ground_mount"},
        {displayValue:"Roof Mount", value:"roof_mount"}   
      ],
      images:[groundMountPicture, roofMountPicture],
      followUpRequiredValue: "roof_mount",
      followUp: {
        id:'type_of_roof',
        type: 'select',
        text: "What kind of roof are we working with?",
        select: roofTypes.map((option, index) => (
          <option key={index} value={option}>{option}</option>
        ))
      }
    },
    {
      id:'powerUsage',
      type:'multipleChoice',
      text:"Do we know how much power you use daily?",
      select:["Yes", "No"],
      followUpRequiredValue: "Yes",
      followUp: {
        id: "dailyPowerUsageAmount",
        id2: "monthlyPowerUsageAmount",
        type: "select",
        select: dailyPowerUsage.map((option,index) => (
          <option key={index} value={option}>{option}</option>
        )),
        select2: monthlyPowerUsage.map((option,index) => (
          <option key={index} value={option}>{option}</option>
        )),
        text: "Answer one of the following questions",
        text2: "How much power on average do you use in a day?",
        text3: "How much power on average do you use in a month?"
      }
    },
    {
      id:"sustainability",
      type:'select',
      text:"How often do you want to run your generator?",
      select:sustainabilityOptions.map((option, index) => (
        <option key={index} value={option}>{option}</option>
      )),
    },
    {
      id: 'preferredcommunication',
      type: 'multipleChoice',      
      text: 'Preferred form of Communication',
      select: ["Email", "Phone Call", "Text"],
      followUp: {
        text:"",
        id:null,
        type:null,
      }
    },
    {
      id: 'customerComment',
      type: 'input',
      text: "Please share any details that will help us provide you with the best possible service.",
    }
  ];

  
export default offgridQuestionsData;